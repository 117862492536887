import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  TextField,
  Typography
} from "@mui/material"
import { DataGrid, GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from "@mui/x-data-grid"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { JVectorFileInfo, JVectorFileInfoLayer, JVectorFileInfoLayerAttribute, JVectorFileInfoLayerIndexedAttribute, VECTOR_DATA_FILE_FORMATS } from "file/model"
import { useTags } from "organization/hooks"
import { JProjection } from "projection/model"
import { projectionSVC } from "projection/service"
import React, { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { JAttribute, JVectorDataSourceSubmitValues } from "spatialdatasource/model"
import { createDataSource, updateDataSource, updateDataSourceTags } from "spatialdatasource/utils"
import { StatusChip } from "ui/components/StatusChip"
import { STATUS_CHIP_LEVELS } from "ui/model"
import { DataSourcePage } from "./DataSourcePage"

interface JVectorDataSourceFormDialogProps {
  close: () => void
  fileInfos: JVectorFileInfo[] // We currently support only ONE Vector file
  afterSubmit: () => void
}

interface LayerSelection {
  id?: GridRowId
  name: string
  selected: boolean
}

interface JVectorDataSourceFormValues {
  name: string
  description: string
  layer: string // used for single layer formats
  layers: LayerSelection[] // used for multiple layers formats (DWG,DXF)
  crs: JProjection
  columnX: string
  columnY: string
  idAttributeName: string
  tags: string[]
  attributes: JVectorFileInfoLayerAttribute[]
  indexedAttributes: JVectorFileInfoLayerIndexedAttribute[]
}

const ALLOWED_NUMERIC_ATTRIBUTE_TYPES = ["DOUBLE", "FLOAT", "INTEGER"]
const ALLOWED_CSV_ATTRIBUTE_TYPES = ["VARCHAR", "DOUBLE", "INTEGER", "FLOAT", "DATE"]
const SINGLE_LAYERED_FILE_TYPES = [VECTOR_DATA_FILE_FORMATS.GML, VECTOR_DATA_FILE_FORMATS.GEOPACKAGE, VECTOR_DATA_FILE_FORMATS.FILEGDB, VECTOR_DATA_FILE_FORMATS.MAPINFO, VECTOR_DATA_FILE_FORMATS.KML]
const MULTI_LAYERED_FILE_TYPES = [VECTOR_DATA_FILE_FORMATS.DWG, VECTOR_DATA_FILE_FORMATS.DXF]

export const VectorDataSourceFormDialog = (props: JVectorDataSourceFormDialogProps) => {
  // If the SDS is being updated, we will try to set the state of this dialog according
  // to the existing values, as much as possible
  const sdsToUpdate = useAppSelector(state => state.sds.sdsToUpdate)
  const vectorFileInfolayers: JVectorFileInfoLayer[] = React.useMemo(
    () =>
      props.fileInfos[0].metadata.layers.sortByProperty("name").map(layer => ({
        ...layer,
        fileAttributes: layer.fileAttributes.sortByProperty("standardizedName").map((attr, index) => ({
          id: index,
          ...attr
        }))
      })),
    []
  )
  const projectionByLayer = React.useMemo(() => vectorFileInfolayers.map(layer => projectionSVC.getProjections().find((proj: JProjection) => proj.code === layer.crs) || null), [])

  const [indexedAttributes, setIndexedAttributes] = useState<string[]>([])
  const { hasError, errorMessage, handleError, resetError } = useErrorHandling(translate(sdsToUpdate !== null ? "sds.update.error" : "sds.create.error"))

  // The first layer is selected by default, but if the SDS is being updated, search for the already saved layer index by its name
  let initialSelectedLayerIndex = 0
  if (sdsToUpdate) {
    initialSelectedLayerIndex = vectorFileInfolayers.findIndex(l => l.name.toLowerCase() === String(sdsToUpdate.params.layers?.[0]).toLowerCase())
    if (initialSelectedLayerIndex === -1) {
      initialSelectedLayerIndex = 0
    }
  }

  // If the SDS is being updated, we need to make sure that the indexed attributes are still in the list of attributes
  useEffect(() => {
    if (sdsToUpdate) {
      setIndexedAttributes(
        getValues("indexedAttributes")
          .filter((attr: JVectorFileInfoLayerIndexedAttribute) => attr.indexed)
          .map((attr: JVectorFileInfoLayerIndexedAttribute) => attr.name)
      )
    }
  }, [sdsToUpdate])

  const [selectedLayerIndex, setSelectedLayerIndex] = useState(initialSelectedLayerIndex)
  let attributesByLayer: JVectorFileInfoLayerAttribute[][]
  if (sdsToUpdate !== null && props.fileInfos[0].metadata.fileType === VECTOR_DATA_FILE_FORMATS.CSV) {
    // If the SDS is updated with a CSV (which can have only one layer), make sure to preserve the attribute
    // types that were previously modified by the user (note that `sdsToUpdate.attributes` is used intently here,
    // as opposed to `sdsToUpdate.params.attributes`)
    attributesByLayer = [
      vectorFileInfolayers[0].fileAttributes.map(a => {
        const savedAttr = sdsToUpdate.attributes.find((b: JAttribute) => b.name.toLowerCase() === a.standardizedName.toLowerCase())
        if (savedAttr) {
          return {
            ...a,
            type: savedAttr.type
          }
        }
        return a
      })
    ]
  } else {
    attributesByLayer = vectorFileInfolayers.map(layer => layer.fileAttributes)
  }

  let defaultCrs: JProjection | null = projectionByLayer[selectedLayerIndex]
  if (sdsToUpdate !== null && defaultCrs === null) {
    // Possible special case:
    //   (1) The SDS was created with a CSV (so the CRS needs to be defined by the user)
    //   (2) The SDS is then updated with the same CSV (so the CRS coming from the file is still null, but we know it because the user told us)
    // Finally, even though, in that case, the CRS should be found in the master list, if for any reason it's not there, set to null
    // for the dropdown to stay in controlled mode (undefined would set it to uncontrolled).
    defaultCrs = projectionSVC.getProjections().find((proj: JProjection) => proj.code === sdsToUpdate.crs) ?? null
  }

  let defaultColumnX = ""
  if (sdsToUpdate !== null) {
    // Already existing columnX value must be still in the current attributes and numeric
    const attr = attributesByLayer[selectedLayerIndex].find(a => a.standardizedName === sdsToUpdate.params.columnX)
    if (attr && ALLOWED_NUMERIC_ATTRIBUTE_TYPES.includes(attr.type)) {
      defaultColumnX = attr.standardizedName
    }
  }

  let defaultColumnY = ""
  if (sdsToUpdate !== null) {
    // Already existing columnY value must be still in the current attributes and numeric
    const attr = attributesByLayer[selectedLayerIndex].find(a => a.standardizedName === sdsToUpdate.params.columnY)
    if (attr && ALLOWED_NUMERIC_ATTRIBUTE_TYPES.includes(attr.type)) {
      defaultColumnY = attr.standardizedName
    }
  }

  let defaultIdAttributeName

  if (sdsToUpdate !== null) {
    const idAttribute = attributesByLayer[selectedLayerIndex].find(a => a.standardizedName.toLowerCase() === sdsToUpdate.idAttribute?.name?.toLowerCase())
    defaultIdAttributeName = idAttribute ? idAttribute.standardizedName : ""
  }

  const existingTags = useTags()

  const {
    control,
    watch,
    setValue,
    getValues,
    trigger,
    formState: { errors, isSubmitting },
    handleSubmit
  } = useForm<JVectorDataSourceFormValues>({
    defaultValues: {
      // remove file extension
      name: sdsToUpdate !== null ? sdsToUpdate.name : props.fileInfos[0].filename.replace(/\.[^/.]+$/, ""),
      description: sdsToUpdate !== null ? sdsToUpdate.description : "",
      layer: vectorFileInfolayers[selectedLayerIndex].name,
      layers: vectorFileInfolayers.map((l, index) => {
        const attribute = sdsToUpdate !== null ? sdsToUpdate.params.layers?.find((a: any) => String(a).toLowerCase() === l.name.toLowerCase()) : undefined
        return { id: index, name: l.name, selected: attribute !== undefined }
      }),
      crs: defaultCrs,
      columnX: defaultColumnX,
      columnY: defaultColumnY,
      idAttributeName: defaultIdAttributeName,
      tags: sdsToUpdate !== null ? sdsToUpdate.tags.map(t => t.name) : [],
      attributes: attributesByLayer[selectedLayerIndex],
      indexedAttributes: sdsToUpdate !== null ? sdsToUpdate.attributes.map(attr => ({ name: attr.name, indexed: attr.indexed })) : []
    }
  })
  const onSubmit = async (values: JVectorDataSourceFormValues) => {
    if (hasError) {
      resetError()
    }
    const submitValues: JVectorDataSourceSubmitValues = {
      ...values,
      type: "FILE_VECTOR",
      fileId: props.fileInfos[0].id,
      crs: values.crs.code,
      params: {
        attributes: getValues("attributes")
      },
      indexedAttributes: indexedAttributes.map(attr => ({ name: attr, indexed: true }))
    }

    if (props.fileInfos[0].metadata.fileType === VECTOR_DATA_FILE_FORMATS.CSV) {
      submitValues.params = {
        ...submitValues.params,
        columnX: values.columnX,
        columnY: values.columnY
      }
    } else if (SINGLE_LAYERED_FILE_TYPES.includes(props.fileInfos[0].metadata.fileType)) {
      submitValues.params = {
        ...submitValues.params,
        layers: [values.layer]
      }
    } else if (MULTI_LAYERED_FILE_TYPES.includes(props.fileInfos[0].metadata.fileType)) {
      submitValues.params = {
        ...submitValues.params,
        layers: values.layers.filter(l => l.selected).map(l => l.name)
      }
    }

    try {
      if (sdsToUpdate !== null) {
        await updateDataSource({ ...submitValues, id: sdsToUpdate.id })
        await updateDataSourceTags(sdsToUpdate, values.tags)
      } else {
        await createDataSource(submitValues)
      }
    } catch (error: any) {
      handleError(error)
      return
    }

    /** `afterSubmit` callback is defined in {@link DataSourcePage} */
    props.afterSubmit()
  }

  const layerTableColumns: GridColDef[] = [{ field: "name", headerName: translate("label.name"), flex: 2 }]

  const attributeTableColumns: GridColDef[] = [
    { field: "standardizedName", headerName: translate("label.name"), flex: 2 },
    props.fileInfos[0].metadata.fileType === VECTOR_DATA_FILE_FORMATS.CSV
      ? {
          field: "type",
          type: "singleSelect",
          valueOptions: ALLOWED_CSV_ATTRIBUTE_TYPES,
          editable: true,
          flex: 1
        }
      : {
          field: "type",
          headerName: translate("label.type"),
          flex: 1
        },
    {
      field: "indexed",
      headerName: translate("label.indexed"),
      renderCell: params => <Checkbox checked={indexedAttributes.includes(params.row.standardizedName)} onChange={() => handleCheckboxChange(params.row.standardizedName)} />
    }
  ]

  const handleCheckboxChange = (attribute: string) => {
    if (indexedAttributes.includes(attribute)) {
      setIndexedAttributes(indexedAttributes.filter(attr => attr !== attribute))
    } else {
      setIndexedAttributes([...indexedAttributes, attribute])
    }
  }
  return (
    <Dialog maxWidth="md" fullWidth open>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <DialogTitle>{translate("sds.dialog.title") + ` (${props.fileInfos[0].metadata.fileType})`}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                {/* Name */}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="name"
                    rules={{ required: { value: true, message: translate("label.field.required") } }}
                    render={({ field }) => <TextField {...field} required error={errors.name !== undefined} helperText={errors.name?.message} type="text" label={translate("label.name")} fullWidth />}
                  />
                </Grid>

                {/* Layer - single */}
                {SINGLE_LAYERED_FILE_TYPES.includes(props.fileInfos[0].metadata.fileType) && (
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="layer"
                      rules={{ required: { value: true, message: translate("label.field.required") } }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          required
                          label={translate("label.layer")}
                          fullWidth
                          error={errors.layer !== undefined}
                          helperText={errors.layer?.message}
                          onChange={event => {
                            const layerName = event.target.value
                            const layerIndex = vectorFileInfolayers.findIndex(layer => layer.name === layerName)
                            setSelectedLayerIndex(layerIndex)
                            field.onChange(layerName)
                            setValue("crs", projectionByLayer[layerIndex])
                            setValue("attributes", attributesByLayer[layerIndex])
                            // If the currently selected unique identifier cannot be found in
                            // the list of attributes, reset it
                            if (!attributesByLayer[layerIndex].find(attr => attr.standardizedName.toLowerCase() === getValues("idAttributeName")?.toLowerCase())) {
                              setValue("idAttributeName", "")
                            }
                          }}
                          select
                        >
                          {vectorFileInfolayers.map((layer, index) => (
                            <MenuItem key={index} value={layer.name}>
                              {layer.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                )}

                {/* Layers - multiple */}
                {MULTI_LAYERED_FILE_TYPES.includes(props.fileInfos[0].metadata.fileType) && (
                  <Grid item flexGrow={2} xs={12}>
                    <Controller
                      control={control}
                      name="layers"
                      rules={{
                        required: true,
                        validate: v => {
                          if (v.filter(l => l.selected).length === 0) {
                            return translate("label.field.required")
                          }
                          return true
                        }
                      }}
                      render={({ field }) => (
                        <Stack height={300}>
                          <InputLabel error={errors.layers !== undefined} shrink={true}>
                            {translate("label.layers")}
                          </InputLabel>
                          <DataGrid
                            {...field}
                            sx={{ borderWidth: "1px !important" }}
                            selectionModel={getValues("layers")
                              .map((l, index) => ({ layerIndex: index, selected: l.selected }))
                              .filter(l => l.selected)
                              .map(l => l.layerIndex)}
                            onSelectionModelChange={(selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => {
                              setValue(
                                "layers",
                                getValues("layers").map((ls, index) => ({ id: index, name: ls.name, selected: selectionModel.contains(index) } as LayerSelection))
                              )
                              trigger("layers")
                            }}
                            checkboxSelection
                            rows={watch("layers")}
                            columns={layerTableColumns}
                            hideFooter={true}
                          />
                          <FormHelperText error={errors.layers !== undefined}>{errors.layers?.message || "\u00A0"}</FormHelperText>
                        </Stack>
                      )}
                    />
                  </Grid>
                )}

                {/* CRS */}
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="crs"
                    rules={{ required: { value: true, message: translate("label.field.required") } }}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        disabled={projectionByLayer[selectedLayerIndex] !== null}
                        options={projectionSVC.getProjections()}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        onChange={(event, value) => field.onChange(value)}
                        ListboxProps={{ style: { maxHeight: 200 } }}
                        renderInput={params => <TextField {...params} required error={errors.crs !== undefined} helperText={errors.crs?.message} label={translate("label.crs")} />}
                      />
                    )}
                  />
                </Grid>

                {/* X & Y */}
                {props.fileInfos[0].metadata.fileType === VECTOR_DATA_FILE_FORMATS.CSV && (
                  <>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="columnX"
                        rules={{ required: { value: true, message: translate("label.field.required") } }}
                        render={({ field }) => (
                          <TextField {...field} required error={errors.columnX !== undefined} helperText={errors.columnX?.message} label={translate("label.X")} fullWidth select>
                            {watch("attributes")
                              .filter(attr => ALLOWED_NUMERIC_ATTRIBUTE_TYPES.includes(attr.type))
                              .filter(attr => attr.standardizedName !== watch("columnY"))
                              .map(attr => (
                                <MenuItem key={attr.id} value={attr.standardizedName}>
                                  {attr.standardizedName}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Controller
                        control={control}
                        name="columnY"
                        rules={{ required: { value: true, message: translate("label.field.required") } }}
                        render={({ field }) => (
                          <TextField {...field} required error={errors.columnY !== undefined} helperText={errors.columnY?.message} label={translate("label.Y")} fullWidth select>
                            {watch("attributes")
                              .filter(attr => ALLOWED_NUMERIC_ATTRIBUTE_TYPES.includes(attr.type))
                              .filter(attr => attr.standardizedName !== watch("columnX"))
                              .map(attr => (
                                <MenuItem key={attr.id} value={attr.standardizedName}>
                                  {attr.standardizedName}
                                </MenuItem>
                              ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  </>
                )}

                {/* UID */}
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="idAttributeName"
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        disablePortal
                        blurOnSelect
                        options={[""].concat(attributesByLayer[selectedLayerIndex].map(attr => attr.standardizedName))}
                        onChange={(event, value) => field.onChange(value)}
                        ListboxProps={{ style: { maxHeight: 200 } }}
                        renderInput={params => <TextField {...params} label={translate("label.uniqueIdentifier")} />}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Attributes */}
            <Grid item xs={6}>
              <Stack>
                <Stack sx={{ height: 400, width: "100%" }} mb={2} padding={1}>
                  <Controller
                    control={control}
                    name="attributes"
                    render={({ field }) => (
                      <InputLabel error={errors.attributes !== undefined} shrink={true}>
                        {translate("sds.attributes")}
                      </InputLabel>
                    )}
                  />
                  {/*
                    For the moment DataGridWithSingleClickCellEdit does not work because for
                    some reason the onCellEditCommit callback is not working
                  */}
                  <DataGrid
                    rows={watch("attributes")}
                    columns={attributeTableColumns}
                    hideFooter
                    disableSelectionOnClick
                    onCellEditCommit={(params, event, detail) => {
                      const newAttributes = attributesByLayer[selectedLayerIndex].map(attr => (attr.id === params.id ? { ...attr, type: params.value } : attr))
                      // When the type of an attribute is changed to non-numeric, we need to make sure
                      // to update the values of columnX/Y accordingly
                      for (const field of ["columnX", "columnY"] as Array<keyof JVectorDataSourceFormValues>) {
                        if (newAttributes.find(attr => attr.standardizedName === getValues(field) && !ALLOWED_NUMERIC_ATTRIBUTE_TYPES.includes(attr.type))) {
                          // Field is no longer numeric, so reset it
                          setValue(field, "")
                        }
                      }
                      // Watch out this setValue must come at the end (after the previous ones), to avoid RHF state sync issues!
                      setValue("attributes", newAttributes)
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>

            {/* Description */}
            <Grid item xs={6}>
              <Controller control={control} name="description" render={({ field }) => <TextField {...field} multiline type="text" label={translate("label.description")} fullWidth />} />
            </Grid>

            {/* Tags */}
            <Grid item xs={6}>
              <Controller
                control={control}
                name="tags"
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={existingTags.map(t => t.name)}
                    disableListWrap
                    onChange={(_event, tags) => field.onChange(tags)}
                    renderInput={params => <TextField {...params} label={translate("label.tags")} />}
                    multiple
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => <StatusChip label={option} level={STATUS_CHIP_LEVELS.NEUTRAL} {...getTagProps({ index })} />)
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ justifyContent: "space-between" }}>
          {hasError ? (
            <Typography color="error" sx={{ marginLeft: "0.5em" }}>
              {errorMessage}
            </Typography>
          ) : (
            <div />
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            {isSubmitting && <CircularProgress size={20} />}
            <Button disabled={isSubmitting} variant="outlined" onClick={props.close}>
              {translate("button.cancel")}
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              {translate(sdsToUpdate !== null ? "button.save" : "button.create")}
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  )
}
