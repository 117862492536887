import { store } from "app/store/store"
import { JPage, JPageService } from "./model"
import { getPageByPath, refreshPages } from "./tools/common"

refreshPages()

export const pageSVC: JPageService = {
  getAll(): JPage[] {
    return store.getState().page.pages.slice()
  },
  getActive(): JPage {
    return { ...store.getState().page.activePage }
  },
  getByPath: getPageByPath,
  existByPath(pagePath: string): boolean {
    return store.getState().page.pages.findByProperty("path", pagePath) !== undefined
  }
}
