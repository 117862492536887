import { IconDefinition } from "@fortawesome/fontawesome-svg-core"
import { getRouteBasePath } from "app/utils/common"
import { MEMBER_ROLES } from "user/model"

export const LOGIN_PATH: string = `${getRouteBasePath()}/login`
export const DASHBOARD_PATH: string = `${getRouteBasePath()}/`
export const ORGANIZATION_PATH: string = `${getRouteBasePath()}/organization`
export const SDS_PATH: string = `${getRouteBasePath()}/sources`
export const PROJECT_PATH: string = `${getRouteBasePath()}/projects`
export const JOB_PATH: string = `${getRouteBasePath()}/jobs`

export interface JPage {
  path: string // must start with "/"
  titleTranslationKey: string // pages are stored in the default page state, we can't translate them during store initialization
  jsxElement: any
  icon?: IconDefinition
  parentPath?: string
  minimumRequiredRole: MEMBER_ROLES
}

export interface JPageService {
  getAll(): JPage[]
  getActive(): JPage
  getByPath(pagePath: string): JPage | null
  existByPath(pagePath: string): boolean
}

export interface JPageState {
  pages: JPage[]
  activePage: JPage
}

export interface JPageServiceState {
  allPages: JPage[]
}
