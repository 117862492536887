// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../font/barlow/stylesheet.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!../font/IBMPlexMono/stylesheet.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".noselect {\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */\n}\n\n#app-container {\n  width: 100%;\n  min-width: 100%;\n  max-width: 100%;\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n* {\n  box-sizing: border-box;\n  font-family: \"Barlow\";\n  outline: none;\n}\n", "",{"version":3,"sources":["webpack://./src/resources/css/main.css"],"names":[],"mappings":"AAGA;EACE,2BAA2B,EAAE,eAAe;EAC5C,yBAAyB,EAAE,WAAW;EACtC,wBAAwB,EAAE,mBAAmB;EAC7C,sBAAsB,EAAE,4BAA4B;EACpD,qBAAqB,EAAE,2BAA2B;EAClD,iBAAiB,EAAE,iFAAiF;AACtG;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;AACf","sourcesContent":["@import \"../font/barlow/stylesheet.css\";\n@import \"../font/IBMPlexMono/stylesheet.css\";\n\n.noselect {\n  -webkit-touch-callout: none; /* iOS Safari */\n  -webkit-user-select: none; /* Safari */\n  -khtml-user-select: none; /* Konqueror HTML */\n  -moz-user-select: none; /* Old versions of Firefox */\n  -ms-user-select: none; /* Internet Explorer/Edge */\n  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */\n}\n\n#app-container {\n  width: 100%;\n  min-width: 100%;\n  max-width: 100%;\n  height: 100%;\n  min-height: 100%;\n  max-height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n* {\n  box-sizing: border-box;\n  font-family: \"Barlow\";\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
