import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Button,
  useTheme,
  Tab,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  LinearProgressProps,
  Radio,
  TextField,
  Typography
} from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import Grid from "@mui/material/Grid"
import { Box, Stack } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { JJob, JOB_STATUS } from "job/model"
import { cancelJob, executeProcess, getJob, getRunningMvtCacheJobForProject } from "job/utils"
import { messageSVC } from "message/service"
import { useInterval } from "project/hooks"
import { JProject } from "project/model"
import { setProjectMvtCacheDialog } from "project/store"
import React from "react"
import { ProjectMvtTileCacheDialogNew } from "./ProjectMvtTileCacheDialogNew"
import { ProjectMvtTileCacheDialogStatus } from "./ProjectMvtTileCacheDialogState"

const JOB_STATUS_REFRESH_INTERVAL_IN_MSECS = 5000

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

interface JProjectMvtTileCacheDialogProps {
  project: JProject
}

export const ProjectMvtTileCacheDialog = (props: JProjectMvtTileCacheDialogProps) => {
  const [selectedProcess, setSelectedProcess] = React.useState<"seed-all-levels" | "seed-some-levels" | "truncate-all-levels" | "truncate-some-levels" | "">("")
  const [runningJob, setRunningJob] = React.useState<JJob | null>(null)
  const [seedProcessLevels, setSeedProcessLevels] = React.useState(["", ""])
  const [truncateProcessLevels, setTruncateProcessLevels] = React.useState(["", ""])
  const [hasSeedProcessLevelErrors, setHasSeedProcessLevelErrors] = React.useState([false, false])
  const [hasTruncateProcessLevelErrors, setHasTruncateProcessLevelErrors] = React.useState([false, false])
  const [isLoading, setIsLoading] = React.useState(false)
  const { hasError, errorMessage, handleError, resetError, setErrorMessageAndActivateError } = useErrorHandling()
  const [activeTab, setActiveTab] = React.useState("new")

  const dispatch = useAppDispatch()
  console.log(props.project)
  React.useEffect(() => {
    setIsLoading(true)
    getRunningMvtCacheJobForProject(props.project.id)
      .then(job => {
        setRunningJob(job)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, translate("server.error"))
        console.error(error)
      })
  }, [props.project])

  useInterval(
    () => {
      if (runningJob) {
        getJob(runningJob.id).then(job => {
          if ([JOB_STATUS.ACCEPTED, JOB_STATUS.RUNNING].includes(job.status)) {
            setRunningJob(job)
          } else {
            setRunningJob(null)
          }
        })
      }
    },
    runningJob ? JOB_STATUS_REFRESH_INTERVAL_IN_MSECS : null
  )

  const getRunningJobDescription = () => {
    if (!runningJob) {
      return ""
    }
    let desc = ""
    if (runningJob.processId === "VTCS:SEED_MVT_CACHE") {
      desc = translate("mvt.cache.caching")
    } else {
      desc = translate("mvt.cache.deleting")
    }
    if ("zMin" in runningJob.inputs || "zMax" in runningJob.inputs) {
      desc += " " + translate("mvt.cache.for.levels", { zMin: runningJob.inputs.zMin, zMax: runningJob.inputs.zMax })
    }
    return desc
  }

  const validateLevelValue = (v: string) => {
    if (/\D/.test(v) || v === "") {
      return false
    }
    const n = Number(v)
    return n >= 0 && n <= 22
  }

  const validateLevelValues = (process: "seed" | "truncate") => {
    let errs
    if (process === "seed") {
      errs = seedProcessLevels.map(validateLevelValue).map(b => !b)
      setHasSeedProcessLevelErrors(errs)
    } else {
      errs = truncateProcessLevels.map(validateLevelValue).map(b => !b)
      setHasTruncateProcessLevelErrors(errs)
    }
    return !errs.some(Boolean)
  }

  const handleLaunchProcess = () => {
    let process = ""
    let levels: any = {}
    resetError()
    setHasSeedProcessLevelErrors([false, false])
    setHasTruncateProcessLevelErrors([false, false])
    if (selectedProcess.startsWith("seed")) {
      process = "VTCS:SEED_MVT_CACHE"
      if (selectedProcess.endsWith("some-levels")) {
        if (validateLevelValues("seed")) {
          levels = { zMin: Number(seedProcessLevels[0]), zMax: Number(seedProcessLevels[1]) }
          if (levels.zMax < levels.zMin) {
            setHasSeedProcessLevelErrors([true, true])
            setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
            return
          }
        } else {
          setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
          return
        }
      }
    } else {
      process = "VTCS:TRUNCATE_MVT_CACHE"
      if (selectedProcess.endsWith("some-levels")) {
        if (validateLevelValues("truncate")) {
          levels = { zMin: Number(truncateProcessLevels[0]), zMax: Number(truncateProcessLevels[1]) }
          if (levels.zMax < levels.zMin) {
            setHasTruncateProcessLevelErrors([true, true])
            setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
            return
          }
        } else {
          setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
          return
        }
      }
    }
    setIsLoading(true)
    executeProcess(process, { inputs: { projectId: props.project.id, ...levels } })
      .then(job => {
        setRunningJob(job)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, translate("server.error"))
        console.error(error)
      })
  }

  const handleCancelJob = () => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.yes"),
      cancelButtonLabel: translate("button.no"),
      isCancelDefault: true,
      title: translate("job.delete.title"),
      message: translate("job.delete.message"),
      onSuccess: () => {
        if (runningJob) {
          cancelJob(runningJob.id).catch(error => {
            handleError(error, translate("server.error"))
            console.error(error)
          })
        }
      }
    })
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue)
  }
  return (
    <Dialog open={props.project !== null} fullWidth maxWidth="lg">
      <DialogTitle>
        {translate("mvt.cache.dialog.title")}
        <Typography variant="subtitle1" component="div">
          {props.project.name[props.project.defaultLanguage]}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TabContext value={activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              sx={{
                "& button": {
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: 500,
                  color: theme => theme.palette.text.primary
                },
                "& button.Mui-selected": {
                  color: theme => theme.palette.primary.main
                }
              }}
              onChange={handleTabChange}
            >
              <Tab label={"New Task"} value={"new"} />
              <Tab label={"Cache State"} value={"state"} />
            </TabList>
          </Box>

          <TabPanel value="new" sx={{ padding: 0 }}>
            <ProjectMvtTileCacheDialogNew project={props.project} />
          </TabPanel>
          <TabPanel value="state" sx={{ padding: 0 }}>
            <ProjectMvtTileCacheDialogStatus project={props.project} />
          </TabPanel>
        </TabContext>
      </DialogContent>
    </Dialog>
  )
}

// import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, LinearProgress, LinearProgressProps, Radio, TextField, Typography } from "@mui/material"
// import Grid from "@mui/material/Grid"
// import { Box, Stack } from "@mui/system"
// import { useErrorHandling } from "app/hook"
// import { translate } from "app/language/service"
// import { useAppDispatch } from "app/store/hooks"
// import { JJob, JOB_STATUS } from "job/model"
// import { cancelJob, executeProcess, getJob, getRunningMvtCacheJobForProject } from "job/utils"
// import { messageSVC } from "message/service"
// import { useInterval } from "project/hooks"
// import { JProject } from "project/model"
// import { setProjectMvtCacheDialog } from "project/store"
// import React from "react"
//
// const JOB_STATUS_REFRESH_INTERVAL_IN_MSECS = 5000
//
// function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
//   return (
//     <Box sx={{ display: "flex", alignItems: "center" }}>
//       <Box sx={{ width: "100%", mr: 1 }}>
//         <LinearProgress variant="determinate" {...props} />
//       </Box>
//       <Box sx={{ minWidth: 35 }}>
//         <Typography variant="body2" color="text.secondary">{`${Math.round(props.value)}%`}</Typography>
//       </Box>
//     </Box>
//   )
// }
//
// interface JProjectMvtTileCacheDialogProps {
//   project: JProject
// }
//
// export const ProjectMvtTileCacheDialog = (props: JProjectMvtTileCacheDialogProps) => {
//   const [selectedProcess, setSelectedProcess] = React.useState<"seed-all-levels" | "seed-some-levels" | "truncate-all-levels" | "truncate-some-levels" | "">("")
//   const [runningJob, setRunningJob] = React.useState<JJob | null>(null)
//   const [seedProcessLevels, setSeedProcessLevels] = React.useState(["", ""])
//   const [truncateProcessLevels, setTruncateProcessLevels] = React.useState(["", ""])
//   const [hasSeedProcessLevelErrors, setHasSeedProcessLevelErrors] = React.useState([false, false])
//   const [hasTruncateProcessLevelErrors, setHasTruncateProcessLevelErrors] = React.useState([false, false])
//   const [isLoading, setIsLoading] = React.useState(false)
//   const { hasError, errorMessage, handleError, resetError, setErrorMessageAndActivateError } = useErrorHandling()
//
//   const dispatch = useAppDispatch()
//
//   React.useEffect(() => {
//     setIsLoading(true)
//     getRunningMvtCacheJobForProject(props.project.id)
//       .then(job => {
//         setRunningJob(job)
//         setIsLoading(false)
//       })
//       .catch(error => {
//         handleError(error, translate("server.error"))
//         console.error(error)
//       })
//   }, [props.project])
//
//   useInterval(
//     () => {
//       if (runningJob) {
//         getJob(runningJob.id).then(job => {
//           if ([JOB_STATUS.ACCEPTED, JOB_STATUS.RUNNING].includes(job.status)) {
//             setRunningJob(job)
//           } else {
//             setRunningJob(null)
//           }
//         })
//       }
//     },
//     runningJob ? JOB_STATUS_REFRESH_INTERVAL_IN_MSECS : null
//   )
//
//   const getRunningJobDescription = () => {
//     if (!runningJob) {
//       return ""
//     }
//     let desc = ""
//     if (runningJob.processId === "VTCS:SEED_MVT_CACHE") {
//       desc = translate("mvt.cache.caching")
//     } else {
//       desc = translate("mvt.cache.deleting")
//     }
//     if ("zMin" in runningJob.inputs || "zMax" in runningJob.inputs) {
//       desc += " " + translate("mvt.cache.for.levels", { zMin: runningJob.inputs.zMin, zMax: runningJob.inputs.zMax })
//     }
//     return desc
//   }
//
//   const validateLevelValue = (v: string) => {
//     if (/\D/.test(v) || v === "") {
//       return false
//     }
//     const n = Number(v)
//     return n >= 0 && n <= 22
//   }
//
//   const validateLevelValues = (process: "seed" | "truncate") => {
//     let errs
//     if (process === "seed") {
//       errs = seedProcessLevels.map(validateLevelValue).map(b => !b)
//       setHasSeedProcessLevelErrors(errs)
//     } else {
//       errs = truncateProcessLevels.map(validateLevelValue).map(b => !b)
//       setHasTruncateProcessLevelErrors(errs)
//     }
//     return !errs.some(Boolean)
//   }
//
//   const handleLaunchProcess = () => {
//     let process = ""
//     let levels: any = {}
//     resetError()
//     setHasSeedProcessLevelErrors([false, false])
//     setHasTruncateProcessLevelErrors([false, false])
//     if (selectedProcess.startsWith("seed")) {
//       process = "VTCS:SEED_MVT_CACHE"
//       if (selectedProcess.endsWith("some-levels")) {
//         if (validateLevelValues("seed")) {
//           levels = { zMin: Number(seedProcessLevels[0]), zMax: Number(seedProcessLevels[1]) }
//           if (levels.zMax < levels.zMin) {
//             setHasSeedProcessLevelErrors([true, true])
//             setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
//             return
//           }
//         } else {
//           setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
//           return
//         }
//       }
//     } else {
//       process = "VTCS:TRUNCATE_MVT_CACHE"
//       if (selectedProcess.endsWith("some-levels")) {
//         if (validateLevelValues("truncate")) {
//           levels = { zMin: Number(truncateProcessLevels[0]), zMax: Number(truncateProcessLevels[1]) }
//           if (levels.zMax < levels.zMin) {
//             setHasTruncateProcessLevelErrors([true, true])
//             setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
//             return
//           }
//         } else {
//           setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
//           return
//         }
//       }
//     }
//     setIsLoading(true)
//     executeProcess(process, { inputs: { projectId: props.project.id, ...levels } })
//       .then(job => {
//         setRunningJob(job)
//         setIsLoading(false)
//       })
//       .catch(error => {
//         handleError(error, translate("server.error"))
//         console.error(error)
//       })
//   }
//
//   const handleCancelJob = () => {
//     messageSVC.confirmDialog({
//       confirmButtonLabel: translate("button.yes"),
//       cancelButtonLabel: translate("button.no"),
//       isCancelDefault: true,
//       title: translate("job.delete.title"),
//       message: translate("job.delete.message"),
//       onSuccess: () => {
//         if (runningJob) {
//           cancelJob(runningJob.id).catch(error => {
//             handleError(error, translate("server.error"))
//             console.error(error)
//           })
//         }
//       }
//     })
//   }
//
//   return (
//     <Dialog open={props.project !== null} fullWidth maxWidth="md">
//       <DialogTitle>{translate("mvt.cache.dialog.title")}</DialogTitle>
//       <DialogContent>
//         <Grid container spacing={3} alignItems="center">
//           {/* Row */}
//           <Grid item xs={2.5} alignSelf={"flex-start"}>
//             <Typography variant="h6">{translate("mvt.cache.state")}</Typography>
//           </Grid>
//           <Grid item xs={9.5}>
//             {translate("mvt.cache.state.non.available")}
//           </Grid>
//
//           {/* Row Remplir */}
//           <Grid item xs={2.5} alignSelf={"flex-start"}>
//             <Typography variant="h6">{translate("mvt.cache.fill")}</Typography>
//           </Grid>
//           <Grid item xs={4.75} alignSelf={"flex-start"}>
//             <Grid container>
//               <Grid item xs={12}>
//                 <FormControlLabel
//                   value="seed-all-levels"
//                   disabled={runningJob !== null || isLoading}
//                   checked={selectedProcess === "seed-all-levels"}
//                   onChange={() => {
//                     setSelectedProcess("seed-all-levels")
//                   }}
//                   control={<Radio />}
//                   label={translate("mvt.cache.all.levels")}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <FormControlLabel
//                   value="seed-some-levels"
//                   checked={selectedProcess === "seed-some-levels"}
//                   disabled={runningJob !== null || isLoading}
//                   onChange={() => {
//                     setSelectedProcess("seed-some-levels")
//                   }}
//                   control={<Radio />}
//                   label={translate("mvt.cache.some.levels")}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item xs={4.75} alignSelf={"flex-end"}>
//             <Grid container alignItems={"center"}>
//               <Grid item xs={5}>
//                 <TextField
//                   placeholder="Min"
//                   value={seedProcessLevels[0]}
//                   onChange={e => {
//                     setSeedProcessLevels([e.target.value, seedProcessLevels[1]])
//                   }}
//                   disabled={selectedProcess !== "seed-some-levels"}
//                   error={hasSeedProcessLevelErrors[0]}
//                 />
//               </Grid>
//               <Grid item xs={2} sx={{ textAlign: "center" }}>
//                 <Typography>-</Typography>
//               </Grid>
//               <Grid item xs={5}>
//                 <TextField
//                   placeholder="Max"
//                   value={seedProcessLevels[1]}
//                   onChange={e => {
//                     setSeedProcessLevels([seedProcessLevels[0], e.target.value])
//                   }}
//                   disabled={selectedProcess !== "seed-some-levels"}
//                   error={hasSeedProcessLevelErrors[1]}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//
//           {/* Row Truncate */}
//           <Grid item xs={2.5} alignSelf={"flex-start"}>
//             <Typography variant="h6">{translate("mvt.cache.delete")}</Typography>
//           </Grid>
//           <Grid item xs={4.75} alignSelf={"flex-start"}>
//             <Grid container>
//               <Grid item xs={12}>
//                 <FormControlLabel
//                   value="truncate-all-levels"
//                   checked={selectedProcess === "truncate-all-levels"}
//                   disabled={runningJob !== null || isLoading}
//                   onChange={() => {
//                     setSelectedProcess("truncate-all-levels")
//                   }}
//                   control={<Radio />}
//                   label={translate("mvt.cache.all.levels")}
//                 />
//               </Grid>
//               <Grid item xs={12}>
//                 <FormControlLabel
//                   value="truncate-some-levels"
//                   checked={selectedProcess === "truncate-some-levels"}
//                   disabled={runningJob !== null}
//                   onChange={() => {
//                     setSelectedProcess("truncate-some-levels")
//                   }}
//                   control={<Radio />}
//                   label={translate("mvt.cache.some.levels")}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//           <Grid item xs={4.75} alignSelf={"flex-end"}>
//             <Grid container alignItems={"center"}>
//               <Grid item xs={5}>
//                 <TextField
//                   placeholder="Min"
//                   value={truncateProcessLevels[0]}
//                   onChange={e => {
//                     setTruncateProcessLevels([e.target.value, truncateProcessLevels[1]])
//                   }}
//                   disabled={selectedProcess !== "truncate-some-levels"}
//                   error={hasTruncateProcessLevelErrors[0]}
//                 />
//               </Grid>
//               <Grid item xs={2} sx={{ textAlign: "center" }}>
//                 <Typography>-</Typography>
//               </Grid>
//               <Grid item xs={5}>
//                 <TextField
//                   placeholder="Max"
//                   value={truncateProcessLevels[1]}
//                   onChange={e => {
//                     setTruncateProcessLevels([truncateProcessLevels[0], e.target.value])
//                   }}
//                   disabled={selectedProcess !== "truncate-some-levels"}
//                   error={hasTruncateProcessLevelErrors[1]}
//                 />
//               </Grid>
//             </Grid>
//           </Grid>
//
//           {/* Row */}
//           <Grid item xs={2.5}>
//             <Typography variant="h6">{translate("mvt.cache.ongoing")}</Typography>
//           </Grid>
//           {runningJob ? (
//             <>
//               <Grid item xs={4.5}>
//                 {getRunningJobDescription()}
//               </Grid>
//
//               <Grid item xs={4}>
//                 <LinearProgressWithLabel value={runningJob.progress ?? 0} />
//               </Grid>
//
//               <Grid item xs={1}>
//                 <FontAwesomeIcon icon={light("circle-xmark")} cursor={"pointer"} size="1x" onClick={handleCancelJob} />
//               </Grid>
//             </>
//           ) : (
//             <Grid item xs={9.5}></Grid>
//           )}
//         </Grid>
//       </DialogContent>
//       <DialogActions sx={{ justifyContent: "space-between" }}>
//         {hasError ? (
//           <Typography color="error" sx={{ marginLeft: "0.5em" }}>
//             {errorMessage}
//           </Typography>
//         ) : (
//           <div />
//         )}
//         <Stack direction="row" alignItems="center" spacing={1}>
//           {isLoading && <CircularProgress size={20} />}
//           <Button
//             variant="outlined"
//             onClick={() => {
//               setRunningJob(null)
//               dispatch(setProjectMvtCacheDialog(null))
//             }}
//           >
//             {translate("button.cancel")}
//           </Button>
//           <Button disabled={selectedProcess === "" || runningJob != null} onClick={handleLaunchProcess}>
//             {translate("mvt.cache.launch.process")}
//           </Button>
//         </Stack>
//       </DialogActions>
//     </Dialog>
//   )
// }
