import { Button, useTheme, CircularProgress, FormControl, InputLabel, MenuItem, DialogActions, DialogContent, LinearProgress, LinearProgressProps, Typography, Select } from "@mui/material"
import Grid from "@mui/material/Grid"
import { Box, Stack } from "@mui/system"
import { useErrorHandling } from "app/hook"
import { translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { JJob, JOB_STATUS } from "job/model"
import { cancelJob, executeProcess, getJob, getRunningMvtCacheJobForProject } from "job/utils"
import { messageSVC } from "message/service"
import { useInterval } from "project/hooks"
import { JProject, TASK_TYPES, TASK_LEVELS, JServerExtent } from "project/model"
import { setProjectMvtCacheDialog } from "project/store"
import React from "react"
import { SelectAreaMap } from "map/components/SelectAreaMap"

const JOB_STATUS_REFRESH_INTERVAL_IN_MSECS = 5000

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", margin: "20px 0" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" sx={{ height: "10px" }} {...props} />
      </Box>
    </Box>
  )
}
const levelsChoices: number[] = []
for (let i = 0; i <= 22; i++) {
  levelsChoices.push(i)
}
interface ProjectMvtTileCacheDialogNewProps {
  project: JProject
}
export const ProjectMvtTileCacheDialogNew = ({ project }: ProjectMvtTileCacheDialogNewProps) => {
  const [runningJob, setRunningJob] = React.useState<JJob | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const { hasError, errorMessage, handleError, resetError, setErrorMessageAndActivateError } = useErrorHandling()
  const theme = useTheme()
  const [taskType, setTaskType] = React.useState<TASK_TYPES>(TASK_TYPES.FILL)
  const [taskLevels, setTaskLevels] = React.useState<TASK_LEVELS>(TASK_LEVELS.ALL)
  const [minLevel, setMinLevel] = React.useState("")
  const [maxLevel, setMaxLevel] = React.useState("")
  const [extent, setExtent] = React.useState<JServerExtent | null>(project.initialExtent)

  console.log("extent", extent)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    setIsLoading(true)
    getRunningMvtCacheJobForProject(project.id)
      .then(job => {
        setRunningJob(job)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, translate("server.error"))
        console.error(error)
      })
  }, [project])

  useInterval(
    () => {
      if (runningJob) {
        getJob(runningJob.id).then(job => {
          if ([JOB_STATUS.ACCEPTED, JOB_STATUS.RUNNING].includes(job.status)) {
            setRunningJob(job)
          } else {
            setRunningJob(null)
          }
        })
      }
    },
    runningJob ? JOB_STATUS_REFRESH_INTERVAL_IN_MSECS : null
  )

  const handleLaunchProcess = () => {
    resetError()
    let levels: any = {}
    if (taskLevels === TASK_LEVELS.SOME) {
      if (minLevel === "" || maxLevel === "") {
        setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
        return
      }
      if (minLevel > maxLevel) {
        setErrorMessageAndActivateError(translate("mvt.cache.error.levels.message"))
        return
      }
      levels = { zMin: minLevel, zMax: maxLevel }
    }

    const process = taskType === TASK_TYPES.FILL ? "VTCS:SEED_MVT_CACHE" : "VTCS:TRUNCATE_MVT_CACHE"

    setIsLoading(true)
    executeProcess(process, { inputs: { projectId: project.id, ...levels } })
      .then(job => {
        setRunningJob(job)
        setIsLoading(false)
      })
      .catch(error => {
        handleError(error, translate("server.error"))
        console.error(error)
      })
  }

  return (
    <>
      <DialogContent sx={{ padding: "40px 0", borderBottom: "3px solid ", borderColor: `${theme.palette.grey[300]}` }}>
        {!runningJob && (
          <Stack gap={10} display="flex" direction="row" justifyContent="space-between">
            <Stack display="flex" direction="column" flex={1}>
              <FormControl sx={{ m: 1, minWidth: 120, margin: "0 0 0.5rem 0" }}>
                <Typography>Task type</Typography>
                <Select id="type" value={taskType} onChange={e => setTaskType(e.target.value as TASK_TYPES)}>
                  <MenuItem value={TASK_TYPES.FILL}>Fill</MenuItem>
                  <MenuItem value={TASK_TYPES.DELETE}>Delete</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120, margin: "0.5rem 0" }}>
                <Typography>Levels</Typography>
                <Select id="level" value={taskLevels} onChange={e => setTaskLevels(e.target.value as TASK_LEVELS)}>
                  <MenuItem value={TASK_LEVELS.ALL}>All</MenuItem>
                  <MenuItem value={TASK_LEVELS.SOME}>Some</MenuItem>
                </Select>
              </FormControl>
              <Stack direction="row" gap={5}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, flex: "1" }}>
                  <InputLabel id="min-label">Min</InputLabel>
                  <Select
                    labelId="min-label"
                    id="min"
                    label="min"
                    value={minLevel}
                    disabled={taskLevels === TASK_LEVELS.ALL}
                    onChange={e => {
                      setMinLevel(e.target.value)
                    }}
                  >
                    {levelsChoices.map((level, index) => (
                      <MenuItem key={index} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120, flex: "1" }}>
                  <InputLabel id="max-label">Max</InputLabel>
                  <Select labelId="max-label" id="max" label="max" value={maxLevel} disabled={taskLevels === TASK_LEVELS.ALL} onChange={e => setMaxLevel(e.target.value)}>
                    {levelsChoices.map((level, index) => (
                      <MenuItem key={index} value={level}>
                        {level}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <Stack display="flex" direction="column" flex={1}>
              <Typography>Specify the task area</Typography>
              <Grid sx={{ width: "100%", aspectRatio: "3/2", backgroundColor: "grey" }}>
                <SelectAreaMap project={project} setExtent={setExtent} />
              </Grid>
            </Stack>
          </Stack>
        )}

        {runningJob && <TaskProgress runningJob={runningJob} handleError={handleError} />}
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        {hasError ? (
          <Typography color="error" sx={{ marginLeft: "0.5em" }}>
            {errorMessage}
          </Typography>
        ) : (
          <div />
        )}
        <Stack direction="row" alignItems="center" spacing={1}>
          {isLoading && <CircularProgress size={20} />}
          <Button
            variant="outlined"
            onClick={() => {
              setRunningJob(null)
              dispatch(setProjectMvtCacheDialog(null))
            }}
          >
            {translate("button.cancel")}
          </Button>
          <Button disabled={runningJob != null} onClick={handleLaunchProcess}>
            {/* <Button disabled={selectedProcess === "" || runningJob != null} onClick={handleLaunchProcess}> */}
            {translate("mvt.cache.launch.process")}
          </Button>
        </Stack>
      </DialogActions>
    </>
  )
}

interface taskTypeProps {
  runningJob: JJob
  handleError: (error: any, message: string) => void
}
const TaskProgress = ({ runningJob, handleError }: taskTypeProps) => {
  const theme = useTheme()
  const getRunningJobDescription = () => {
    if (!runningJob) {
      return ""
    }
    let desc = ""
    if (runningJob.processId === "VTCS:SEED_MVT_CACHE") {
      desc = translate("mvt.cache.caching")
    } else {
      desc = translate("mvt.cache.deleting")
    }
    desc += " - "
    if ("zMin" in runningJob.inputs || "zMax" in runningJob.inputs) {
      desc += translate("mvt.cache.for.levels", { zMin: runningJob.inputs.zMin, zMax: runningJob.inputs.zMax })
    } else {
      desc += translate("mvt.cache.for.all.levels")
    }
    return desc
  }

  const handleCancelJob = () => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.yes"),
      cancelButtonLabel: translate("button.no"),
      isCancelDefault: true,
      title: translate("job.delete.title"),
      message: translate("job.delete.message"),
      onSuccess: () => {
        if (runningJob) {
          cancelJob(runningJob.id).catch(error => {
            handleError(error, translate("server.error"))
            console.error(error)
          })
        }
      }
    })
  }
  return (
    <>
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Grid>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              A task is running
            </Typography>
            <Grid>{getRunningJobDescription()}</Grid>
          </Grid>
          <Typography variant="h5" sx={{ color: `${theme.palette.primary.dark}` }}>
            {runningJob.progress ?? 0}%
          </Typography>
        </Stack>

        <LinearProgressWithLabel value={runningJob.progress ?? 0} />

        <Grid>Please wait for the current task to complete before starting a new one</Grid>
        {/* <Grid item xs={1}> */}
        {/*   <FontAwesomeIcon icon={light("circle-xmark")} cursor={"pointer"} size="1x" onClick={handleCancelJob} /> */}
        {/* </Grid> */}
      </Stack>
    </>
  )
}
