import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import { Button, IconButton, Menu, MenuItem, Stack, Typography, useTheme } from "@mui/material"
import { GridSelectionModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { FileSelectDialog } from "file/components/FileSelectDialog"
import { JFileInfo } from "file/model"
import { messageSVC } from "message/service"
import React from "react"
import { JDataSource } from "spatialdatasource/model"
import { reload, setSdsToUpdate } from "spatialdatasource/store"
import { deleteDataSource } from "spatialdatasource/utils"
import { DataSourceDetailsDialog } from "./DataSourceDetailsDialog"
import { DataSourceGrid } from "./DataSourceGrid"
import { WmsWmtsDataSourceFormDialog } from "./WmsWmtsDataSourceFormDialog"

type JCreateDataSourceDialog = "FILE" | "WMS/WMTS" | null

const CreateButtonMenu = (props: { onClick: (dialog: JCreateDataSourceDialog) => void }): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          setAnchorEl(event.currentTarget)
        }}
        sx={{ minWidth: "10rem" }}
      >
        {translate("sds.create.title")}
      </Button>
      <Menu PaperProps={{ sx: { width: anchorEl?.offsetWidth } }} anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            props.onClick("FILE")
            handleClose()
          }}
        >
          {translate("sds.create.button.menu.data.file")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onClick("WMS/WMTS")
            handleClose()
          }}
        >
          {translate("sds.create.button.menu.wms.wmst.service")}
        </MenuItem>
        <MenuItem disabled>{translate("sds.create.button.menu.vector.tile.service")}</MenuItem>
        <MenuItem disabled>{translate("sds.create.button.menu.feature.service")}</MenuItem>
      </Menu>
    </div>
  )
}

export const DataSourcePanel = (props: { setCurrentFileInfos: (fileInfos: JFileInfo[]) => void }) => {
  const [openDialog, setOpenDialog] = React.useState<JCreateDataSourceDialog>(null)
  const [dataSourceToDisplay, setDataSourceToDisplay] = React.useState<JDataSource | null>(null)
  const [selectedDataSourceIds, setSelectedDataSourceIds] = React.useState<GridSelectionModel>([])

  const theme = useTheme()

  const sdsToUpdate = useAppSelector(state => state.sds.sdsToUpdate)

  const dispatch = useAppDispatch()

  const deleteSelectedDataSources = () => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.delete"),
      cancelButtonLabel: translate("button.cancel"),
      isCancelDefault: true,
      title: translate("sds.sds.delete.title", { numDataSources: selectedDataSourceIds.length }),
      message: translate("sds.sds.delete.message", {
        numDataSources: selectedDataSourceIds.length
      }),
      onSuccess: () => {
        const promises = selectedDataSourceIds.map(id => deleteDataSource(id as string))
        Promise.all(promises)
          .then(() => {
            dispatch(reload())
          })
          .catch(error => {
            console.error(error)
          })
      }
    })
  }

  return (
    // Main vertical container
    <Stack spacing={2} height="100%">
      {/* Top horizontal container, below tabs, with widgets on left and create button on right */}
      <Stack direction="row" justifyContent="space-between">
        {/* Widgets on left */}
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton sx={{ marginLeft: "0.70rem" }} color="primary" size="small" onClick={() => dispatch(reload())}>
            <FontAwesomeIcon icon={light("refresh")} />
          </IconButton>
          {selectedDataSourceIds.length > 0 && (
            <>
              <Typography color={theme.palette.text.primary}>
                {translate("sds.sds.selected.count", {
                  numDataSources: selectedDataSourceIds.length
                })}
              </Typography>
              <Button onClick={deleteSelectedDataSources}>{translate("label.delete")}</Button>
            </>
          )}
        </Stack>
        {/* Create button on right */}
        <CreateButtonMenu onClick={dialog => setOpenDialog(dialog)} />
      </Stack>

      {/* Note: controlling the presence of the dialog this way, instead of using its `open` props,
                dispenses from having to manage the reset of its state, when reopening it */}
      {(openDialog === "FILE" || sdsToUpdate !== null) && (
        <FileSelectDialog
          onFilesReady={fileInfos => {
            props.setCurrentFileInfos(fileInfos)
            setOpenDialog(null)
          }}
          close={() => {
            setOpenDialog(null)
            dispatch(setSdsToUpdate(null))
          }}
        />
      )}

      {openDialog === "WMS/WMTS" && (
        <WmsWmtsDataSourceFormDialog
          close={() => {
            setOpenDialog(null)
          }}
          afterSubmit={() => {
            setOpenDialog(null)
            dispatch(reload()) // reload SDS grid
          }}
        />
      )}

      {dataSourceToDisplay && (
        <DataSourceDetailsDialog
          dataSource={dataSourceToDisplay}
          onClose={() => {
            setDataSourceToDisplay(null)
            dispatch(reload())
          }}
        />
      )}

      <DataSourceGrid {...{ setDataSourceToDisplay, selectedDataSourceIds, setSelectedDataSourceIds }} />
    </Stack>
  )
}
