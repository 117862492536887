import * as React from "react"

type IntervalFunction = () => unknown

// This idea comes from: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

export function useInterval(callback: IntervalFunction, delay: number | null) {
  const savedCallback = React.useRef<IntervalFunction | null>(null)

  React.useEffect(() => {
    if (delay !== null) {
      savedCallback.current = callback
    }
  })

  React.useEffect(() => {
    if (delay === null) {
      return
    }
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current()
      }
    }
    const id = setInterval(tick, delay)
    return () => clearInterval(id)
  }, [delay])
}
