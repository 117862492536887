import { regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Stack } from "@mui/material"
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { JMember } from "member/model"
import { getAllMembers } from "member/tools/common"
import { OrganizationInvitationCreateDialog } from "organization/components/OrganizationInvitationCreateDialog"
import React from "react"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { useHoverableDataGridRows } from "ui/hooks"
import { getUserOrganization } from "user/tools/common"
import { MemberDeleteDialog } from "./MemberDeleteDialog"
import { MemberDetailsDialog } from "./MemberDetailsDialog"
import { MemberGridRowMenu } from "./MemberGridRowMenu"
import { MemberUpdateDialog } from "./MemberUpdateDialog"

export const MemberGrid = (): JSX.Element => {
  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()
  const [members, setMembers] = React.useState<JMember[]>([])
  const [selectedMember, setSelectedMember] = React.useState<JMember | null>(null)
  const [isDetailsDialogOpened, setIsDetailsDialogOpened] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(true)
  const [hasLoadingError, setHasLoadingError] = React.useState(false)
  const [isInvitationDialogOpened, setIsInvitationDialogOpened] = React.useState(false)
  const [isUpdatePopupOpened, setIsUpdatePopupOpened] = React.useState(false)
  const [isDeleteMemberDialogOpened, setIsDeleteMemberDialogOpened] = React.useState(false)
  const [reloadCounter, setReloadCounter] = React.useState(0)

  React.useEffect(() => {
    setIsLoading(true)
    getAllMembers(getUserOrganization().id)
      .then(fetchedMembers => {
        fetchedMembers = fetchedMembers.sort((u1, u2) => {
          if (u1.email < u2.email) {
            return -1
          }
          if (u1.email === u2.email) {
            return 0 // should not happen ...
          }
          return 1
        })
        setIsLoading(false)
        setMembers(fetchedMembers)
      })
      .catch(error => {
        console.error(error)
        setMembers([])
        setIsLoading(false)
        setHasLoadingError(true)
      })
  }, [reloadCounter])

  const reloadGrid = () => {
    setReloadCounter(v => v + 1)
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: translate("label.name"), minWidth: 250, flex: 3 },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, any, any>) => {
        if (params.id === hoveredRowId) {
          return (
            <MemberGridRowMenu
              onClose={() => setHoveredRowId(null)}
              memberId={params.id as string}
              name={params.row.name}
              onUpdateClick={() => {
                setSelectedMember(params.row)
                setIsUpdatePopupOpened(true)
              }}
              onShowEditDialog={() => {
                setSelectedMember(params.row)
                setIsDetailsDialogOpened(true)
              }}
              onDeleteClick={() => {
                setSelectedMember(params.row)
                setIsDeleteMemberDialogOpened(true)
              }}
            />
          )
        } else {
          return null
        }
      }
    },
    { field: "id", headerName: translate("label.id"), minWidth: 250, flex: 2 },
    { field: "email", headerName: translate("label.email"), minWidth: 250, flex: 3 }
  ]
  return (
    <Stack
      sx={{
        height: "100%"
      }}
    >
      <Stack direction="row" justifyContent="end" sx={{ marginBottom: "1rem", marginTop: "2rem" }}>
        <Button startIcon={<FontAwesomeIcon className="create-button" icon={regular("plus")} />} onClick={() => setIsInvitationDialogOpened(true)}>
          {translate("organization.invitation.create.title")}
        </Button>
      </Stack>
      <PortalDataGrid
        rowType="member"
        rows={members}
        columns={columns}
        className="member-list-data-grid"
        loading={isLoading}
        error={hasLoadingError}
        autoPageSize
        componentsProps={{
          row: rowHandlers
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "name", sort: "asc" }]
          }
        }}
      />

      {isDetailsDialogOpened && selectedMember && <MemberDetailsDialog member={selectedMember} onClose={() => setIsDetailsDialogOpened(false)} />}

      {isInvitationDialogOpened && (
        <OrganizationInvitationCreateDialog
          onClose={() => setIsInvitationDialogOpened(false)}
          onCreate={() => {
            setIsInvitationDialogOpened(false)
          }}
        />
      )}

      {isUpdatePopupOpened && selectedMember && <MemberUpdateDialog member={selectedMember} onClose={() => setIsUpdatePopupOpened(false)} onUpdate={reloadGrid} />}

      {isDeleteMemberDialogOpened && selectedMember && <MemberDeleteDialog member={selectedMember} onDelete={reloadGrid} onClose={() => setIsDeleteMemberDialogOpened(false)} />}
    </Stack>
  )
}
