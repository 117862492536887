import { appCFG } from "app/config"
import { historySVC } from "app/history/service"
import { store } from "app/store/store"
import { getDateFnsDateFormat, getDateFnsLocale, is12HoursTimeFormat } from "app/tool/date/tools/common"
import { localStorageSVC } from "app/tool/local-storage/service"
import IntlMessageFormat from "intl-messageformat"
import { languageEventModule } from "./event"
import { startLanguageListeners } from "./listeners"
import {
  DEFAULT_LOCALE,
  JCoreTranslateFn,
  JLANGUAGE_EVENTS,
  JLanguageEventLocaleChangeParams,
  JLanguageService,
  JTranslateParamValues,
  JTranslateParams,
  JTranslationByLocale,
  LOCALES,
  PARAM_OVERRIDEN_LOCALE,
  USER_CHANGE_LOCALE_LOCAL_STORAGE_ID
} from "./model"
import { isValidLocale, setCurrentLocaleFromContext } from "./tools/common"

const language = appCFG.language

startLanguageListeners()

export const languageSVC: JLanguageService = {
  getLocale(): LOCALES {
    return store.getState().language.locale
  },
  getDateFnsLocale,
  getDefaultLocale(): LOCALES {
    return DEFAULT_LOCALE
  },
  getLocales(): LOCALES[] {
    return Object.keys(language.translationsByLocale) as LOCALES[]
  },
  setLocale(locale: LOCALES): void {
    if (isValidLocale(locale)) {
      if (locale !== store.getState().language.locale) {
        // USER_CHANGE_LOCALE_LOCAL_STORAGE_ID will be managed in setCurrentLocaleFromContext
        localStorageSVC.set(USER_CHANGE_LOCALE_LOCAL_STORAGE_ID, locale)
        // remove overriden settings
        historySVC.popHashParameters(PARAM_OVERRIDEN_LOCALE)
        if (window.JMAP_OPTIONS?.locale !== undefined) {
          delete window.JMAP_OPTIONS.locale
        }
        setCurrentLocaleFromContext()
        languageEventModule.notify(JLANGUAGE_EVENTS.LOCALE_CHANGE, { locale } as JLanguageEventLocaleChangeParams)
        location.reload()
      }
    } else {
      throw Error(`invalid locale "${locale}"`)
    }
  },
  translate(translationParams: JTranslateParams): string {
    const { key, locale: paramLocale, values } = translationParams
    const defaultLocale = language.defaultLocale ?? DEFAULT_LOCALE
    const locale = !paramLocale ? store?.getState().language.locale : paramLocale
    const localeTranlations = language.translationsByLocale?.[locale]
    let translation: string = localeTranlations ? localeTranlations[key] : ""
    // fallback to default locale if string not found
    if (!translation) {
      if (locale !== defaultLocale) {
        return languageSVC.translate({
          key,
          locale: defaultLocale,
          values
        })
      }
      // if not found at all, return flagged key
      return "??-" + key + "-??"
    }
    // format message
    if (values !== undefined) {
      try {
        translation = new IntlMessageFormat(translation).format(values) as string
      } catch (error) {
        console.error(error)
        // nothing, translation will be returned as-is
      }
    }
    return translation
  },
  getDateFnsDateFormat,
  is12HoursTimeFormat,
  isValidLocale
}

export const translate: JCoreTranslateFn = (key: string, values?: JTranslateParamValues, paramLocale?: LOCALES) => languageSVC.translate({ key, values, locale: paramLocale })

export const getEmptyTranslation = (): JTranslationByLocale => ({
  [LOCALES.EN]: "",
  [LOCALES.FR]: "",
  [LOCALES.ES]: ""
})
