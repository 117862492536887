import { getRestBaseUrl } from "server/tools/common"
import { ABSOLUTE_URL_REGEX, SAAS_GEOJSON_URL_REGEX, SAAS_MAPBOX_URL_REGEX, SAAS_MVT_URL_REGEX, SAAS_WMS_URL_REGEX } from "./model"

export function isJMCAuthenticatedRequest(url: string): boolean {
  const restBaseUrl = getRestBaseUrl()
  // TODO: make this better to take into account round-robin on restBaseUrl in MapBox services
  return (
    (restBaseUrl.match(ABSOLUTE_URL_REGEX) && url.startsWith(restBaseUrl)) ||
    url.match(SAAS_MVT_URL_REGEX) !== null ||
    url.match(SAAS_MAPBOX_URL_REGEX) !== null ||
    url.match(SAAS_GEOJSON_URL_REGEX) !== null ||
    url.match(SAAS_WMS_URL_REGEX) !== null
  )
}
