import { light } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LinearProgress, Tooltip, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { GridColDef, GridRenderCellParams, GridSelectionModel } from "@mui/x-data-grid"
import { languageSVC, translate } from "app/language/service"
import { useAppDispatch, useAppSelector } from "app/store/hooks"
import { dateSVC } from "app/tool/date/service"
import { FILE_INFO_STATUSES, FILE_INFO_TYPES, JFileInfo, VIRTUAL_FILE_INFO_STATUSES } from "file/model"
import { deleteUpload, reload, setFilterModel, setPage, setPageSize, setSortModel, setTotalRowCount, setUpload, setUploadIsPaused, setUploadPercentCompleted } from "file/store"
import { deleteFile, getFileInfos, getFileTypeLabel, getStatusChipFromFileStatus, uploadFile } from "file/utils"
import { filesize } from "filesize"
import { messageSVC } from "message/service"
import React from "react"
import { ButtonMenu, JButtonMenuItem } from "ui/components/ButtonMenu"
import { PortalDataGrid } from "ui/components/PortalDataGrid"
import { useHoverableDataGridRows } from "ui/hooks"
import { getGridDateOperatorsForJMC, getGridStringOperatorsForJMC } from "ui/tools/grid"
import { PARALLEL_FILE_UPLOAD_MAX_COUNT } from "./FilePanel"

interface JFileGridProps {
  setFileInfoToDisplay: (fileInfo: JFileInfo) => void
  setCurrentFileInfos: (fileInfos: JFileInfo[]) => void
  acceptedFiles: File[]
  selectedFileIds: GridSelectionModel
  setSelectedFileIds: (fileIds: GridSelectionModel) => void
}

export const FileGrid = (props: JFileGridProps): JSX.Element => {
  const [fileInfos, setFileInfos] = React.useState<JFileInfo[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [loadingError, setLoadingError] = React.useState<true | null>(null)

  const { hoveredRowId, setHoveredRowId, ...rowHandlers } = useHoverableDataGridRows()

  const { uploadByFileId, uploadIsPausedByFileId, uploadPercentCompletedByFileId, page, pageSize, totalRowCount, sortModel, filterModel, reloadCounter, theme } = useAppSelector(state => ({
    uploadByFileId: state.file.uploadByFileId,
    uploadIsPausedByFileId: state.file.uploadIsPausedByFileId,
    uploadPercentCompletedByFileId: state.file.uploadPercentCompletedByFileId,
    page: state.file.page,
    pageSize: state.file.pageSize,
    totalRowCount: state.file.totalRowCount,
    sortModel: state.file.sortModel,
    filterModel: state.file.filterModel,
    reloadCounter: state.file.reloadCounter,
    theme: state.ui.theme
  }))
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    setIsLoading(true)
    setLoadingError(null)
    getFileInfos(page, pageSize, sortModel, filterModel)
      .then(resp => {
        dispatch(setTotalRowCount(resp.page.totalElements))
        setFileInfos(resp.result)
      })
      .catch(error => {
        console.error(error)
        setLoadingError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [page, pageSize, sortModel, filterModel, reloadCounter])

  React.useEffect(() => {
    const nCurrentFilesUploading = Object.entries(uploadPercentCompletedByFileId).filter(([fileId, perc]) => perc < 100).length

    props.acceptedFiles.forEach((f, idx) => {
      if (nCurrentFilesUploading + idx >= PARALLEL_FILE_UPLOAD_MAX_COUNT) {
        messageSVC.error(
          translate("sds.upload.file.reject.too.many.files.parallel", {
            filename: f.name,
            fileUploadMaxCount: PARALLEL_FILE_UPLOAD_MAX_COUNT
          })
        )
        return
      }

      const upload = uploadFile(f, {
        onSuccess: () => {
          dispatch(reload())
          messageSVC.success(translate("sds.upload.file.complete", { filename: f.name }))
        }
      })

      messageSVC.success(translate("sds.upload.file.started", { filename: f.name }))

      upload.options.onUploadUrlAvailable = () => {
        const fileId = upload.url!.split("/").pop()!
        upload.options.onProgress = (bytesUploaded: number, bytesTotal: number) => {
          const percent = (bytesUploaded / bytesTotal) * 100
          dispatch(setUploadPercentCompleted({ fileId, percent }))
        }

        // When the grid reloads the uploading file should be a new row
        dispatch(reload())

        dispatch(setUpload({ fileId, upload }))
        dispatch(setUploadIsPaused({ fileId, isPaused: false }))

        // Remove this callback once it has run, to avoid rerunning it at unpause
        upload.options.onUploadUrlAvailable = () => void 0
      }
    })
  }, [props.acceptedFiles])

  const columns: Array<GridColDef<JFileInfo, any, any>> = [
    {
      field: "filename",
      headerName: translate("label.name"),
      minWidth: 280,
      flex: 2,
      filterOperators: getGridStringOperatorsForJMC(),
      renderCell: (params: GridRenderCellParams<any, JFileInfo, any>) => {
        const isAnalyzedRasterWithoutCrs = params.row.status === FILE_INFO_STATUSES.ANALYZED && params.row.type === FILE_INFO_TYPES.RASTER_DATA && params.row.metadata.crs === null
        return isAnalyzedRasterWithoutCrs ? (
          <Tooltip title={translate("sds.file.raster.without.crs.tooltip")}>
            <Stack direction="row" alignItems="center">
              <Typography>{params.row.filename}</Typography>
              <Box sx={{ marginLeft: "0.25rem" }}>
                <FontAwesomeIcon color={theme.palette.warning.main} icon={light("triangle-exclamation")} />
              </Box>
            </Stack>
          </Tooltip>
        ) : (
          params.row.filename
        )
      }
    },
    {
      field: "action",
      sortable: false,
      filterable: false,
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      maxWidth: 10,
      align: "right",
      renderCell: (params: GridRenderCellParams<any, JFileInfo, any>) => {
        if (params.id === hoveredRowId) {
          const items: JButtonMenuItem[] = [
            {
              title: translate("sds.create.source"),
              disabled: params.row.status !== FILE_INFO_STATUSES.ANALYZED || (params.row.type === FILE_INFO_TYPES.RASTER_DATA && params.row.metadata.crs === null),
              onClick: () => {
                props.setCurrentFileInfos([params.row])
              }
            },
            {
              title: uploadIsPausedByFileId?.[params.row.id] ? translate("label.unpause.upload") : translate("label.pause.upload"),
              disabled: params.row.status !== FILE_INFO_STATUSES.UPLOADING || typeof uploadByFileId?.[params.row.id] === "undefined",
              onClick: () => {
                if (uploadIsPausedByFileId?.[params.row.id]) {
                  uploadByFileId[params.row.id].start()
                  dispatch(setUploadIsPaused({ fileId: params.row.id, isPaused: false }))
                } else {
                  uploadByFileId[params.row.id].abort()
                  dispatch(setUploadIsPaused({ fileId: params.row.id, isPaused: true }))
                }
              }
            },
            {
              title: translate("sds.file.info.row.menu.label"),
              onClick: () => {
                props.setFileInfoToDisplay(params.row)
              }
            },
            {
              title: translate("label.delete"),
              disabled: params.row.status === FILE_INFO_STATUSES.UPLOADING && uploadIsPausedByFileId?.[params.row.id] === false,
              onClick: () => {
                messageSVC.confirmDialog({
                  confirmButtonLabel: translate("button.delete"),
                  cancelButtonLabel: translate("button.cancel"),
                  isCancelDefault: true,
                  title: translate("sds.file.delete.title", { numFiles: 1 }),
                  message: translate("sds.file.delete.message", { numFiles: 1 }),
                  onSuccess: () => {
                    deleteFile(params.row.id)
                      .then(() => {
                        dispatch(reload())
                        dispatch(deleteUpload(params.row.id))
                      })
                      .catch(error => {
                        console.error(error)
                      })
                  }
                })
              }
            }
          ]
          return <ButtonMenu id={`sds-file-row-menu-${params.row.id}`} small items={items} onClose={() => setHoveredRowId(null)} />
        } else {
          return null
        }
      }
    },
    {
      field: "type",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: params => translate(`sds.file.type.${params.row.type}`),
      headerName: translate("label.type"),
      minWidth: 140,
      flex: 1
    },
    {
      field: "metadata.fileType",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: params => getFileTypeLabel(params.row),
      headerName: translate("label.format"),
      minWidth: 140,
      flex: 1
    },
    {
      field: "fileSize",
      sortable: true,
      filterable: false,
      headerName: translate("label.fileSize"),
      minWidth: 140,
      flex: 1,
      type: "number",
      renderCell: (params: GridRenderCellParams<any, JFileInfo, any>) => filesize(params.row.fileSize, { locale: languageSVC.getLocale() }) // locale only affects the number format, not the unit
    },
    {
      field: "status",
      headerName: translate("label.status"),
      minWidth: 140,
      flex: 1,
      type: "singleSelect",
      valueOptions: Object.values(FILE_INFO_STATUSES),
      renderCell: (params: GridRenderCellParams<any, JFileInfo, any>) => {
        if (params.row.status === FILE_INFO_STATUSES.UPLOADING) {
          if (uploadIsPausedByFileId?.[params.row.id]) {
            return getStatusChipFromFileStatus(VIRTUAL_FILE_INFO_STATUSES.PAUSED)
          } else if (uploadPercentCompletedByFileId.hasOwnProperty(params.row.id)) {
            // The file is currently uploading with TUS
            const perc = uploadPercentCompletedByFileId[params.row.id]
            return (
              <Stack sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={perc} />
              </Stack>
            )
          } else {
            // The file is in the UPLOADING state, but we are not currently uploading it
            // with TUS, so the only way we can safely suggest to the user that the file
            // is RESUMABLE (by re-selecting it with the file picker, but resuming the
            // upload it was left off) is if we find a reference to it in the localStorage,
            // from a previous TUS upload attempt (otherwise it might be a file currently
            // being uploaded by another user).
            for (let i = 0; i < localStorage.length; i++) {
              const key = localStorage.key(i)!
              if (key.startsWith("tus::")) {
                const val = JSON.parse(localStorage.getItem(key)!)
                const fileId = val.uploadUrl!.split("/").pop()!
                if (params.row.id === fileId) {
                  return getStatusChipFromFileStatus(VIRTUAL_FILE_INFO_STATUSES.RESUMABLE)
                }
              }
            }
            // Otherwise we have no information about this file, which might be currently
            // being uploaded by another user
            return getStatusChipFromFileStatus(params.row.status)
          }
        } else {
          return getStatusChipFromFileStatus(params.row.status)
        }
      }
    },
    {
      field: "creationDate",
      filterOperators: getGridDateOperatorsForJMC(),
      type: "date",
      headerName: translate("label.creationDate"),
      renderCell: params => dateSVC.format(params.row.creationDate, { displayTime: true }),
      minWidth: 140,
      flex: 1
    }
  ]

  return (
    <PortalDataGrid
      rowType="file"
      loading={isLoading}
      checkboxSelection
      rows={fileInfos}
      columns={columns}
      paginationMode="server"
      sortingMode="server"
      filterMode="server"
      rowCount={totalRowCount}
      pageSize={pageSize}
      onPageSizeChange={s => dispatch(setPageSize(s))}
      page={page}
      onPageChange={p => dispatch(setPage(p))}
      rowsPerPageOptions={[25, 50, 100]}
      selectionModel={props.selectedFileIds}
      onSelectionModelChange={props.setSelectedFileIds}
      error={loadingError}
      initialState={{
        sorting: {
          sortModel
        },
        filter: {
          filterModel
        }
      }}
      onSortModelChange={m => dispatch(setSortModel(m))}
      onFilterModelChange={m => dispatch(setFilterModel(m))}
      componentsProps={{
        row: rowHandlers
      }}
      onRowDoubleClick={params => props.setFileInfoToDisplay(params.row)}
    />
  )
}
