import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Button, IconButton, Stack } from "@mui/material"
import { translate } from "app/language/service"
import { JRootState } from "app/model"
import { store } from "app/store/store"

import { JProject } from "project/model"
import { reload as reloadProjects, setFormDialog } from "project/store"
import { setProjectDisplayCards } from "project/utils"
import React from "react"
import { connect } from "react-redux"
import { ProjectFormDialog } from "./ProjectFormDialog"

interface JProjectMainHeaderConnectProps {
  displayCards: boolean
  isFormDialogOpen: boolean
  savedProject: JProject
}

const ProjectMainHeaderFn = (props: JProjectMainHeaderConnectProps): JSX.Element => (
  // Top horizontal bar (reload button on left, widgets + create button on right)
  <Stack direction="row" justifyContent="space-between">
    {/* Left: reload button */}
    <IconButton color="primary" size="small" onClick={() => store.dispatch(reloadProjects())}>
      <FontAwesomeIcon icon={light("refresh")} />
    </IconButton>
    {/* Right: widgets + create button */}
    <Stack direction="row" spacing={2}>
      <Box
        sx={{
          "display": "flex",
          "alignItems": "center",
          "justifyContent": "center",
          "& .button": {
            color: theme => theme.palette.text.secondary,
            cursor: "pointer"
          },
          "& .active": {
            color: theme => theme.palette.primary.main,
            cursor: "default"
          },
          "& > *:last-child": {
            marginLeft: "1rem"
          }
        }}
      >
        <FontAwesomeIcon
          className={props.displayCards ? "button active" : "button"}
          icon={regular("grid-2")}
          size="lg"
          onClick={() => {
            if (!props.displayCards) {
              setProjectDisplayCards(true)
            }
          }}
        />
        <FontAwesomeIcon
          className={props.displayCards ? "button" : "button active"}
          icon={regular("list")}
          size="lg"
          onClick={() => {
            if (props.displayCards) {
              setProjectDisplayCards(false)
            }
          }}
        />
      </Box>
      <Button
        startIcon={<FontAwesomeIcon className="create-button" icon={regular("plus")} />}
        onClick={() => {
          store.dispatch(setFormDialog({ isOpen: true }))
        }}
        sx={{ minWidth: "12.5rem" }}
      >
        {translate("project.create.title")}
      </Button>
      {props.isFormDialogOpen && (
        <ProjectFormDialog
          project={props.savedProject}
          onCreate={() => {
            store.dispatch(setFormDialog({ isOpen: false }))
            store.dispatch(reloadProjects())
          }}
          onClose={() => {
            store.dispatch(setFormDialog({ isOpen: false }))
          }}
        />
      )}
    </Stack>
  </Stack>
)

export const ProjectMainHeader = connect(
  (state: JRootState) =>
    ({
      displayCards: state.project.displayCards,
      isFormDialogOpen: state.project.isFormDialogOpen,
      savedProject: state.project.savedProject
    } as JProjectMainHeaderConnectProps)
)(ProjectMainHeaderFn)
