import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { JLocaleTranslation, LOCALES } from "app/language/model"
import { JTag } from "organization/model"
import { JDataGridPagedResponse, JDataGridState } from "ui/tools/grid"

export const enum JMAP_SERVER_DISTANCE_UNITS {
  MILLIMETER = "MILLIMETER",
  CENTIMETER = "CENTIMETER",
  METER = "METER",
  KILOMETER = "KILOMETER",
  INCH = "INCH",
  FOOT = "FOOT",
  YARD = "YARD",
  MILE = "MILE",
  NAUTICAL_MILE = "NAUTICAL_MILE",
  DEGREE_ANGLE = "DEGREE_ANGLE"
}
export const ALL_SERVER_DISTANCE_UNITS: JMAP_SERVER_DISTANCE_UNITS[] = [
  JMAP_SERVER_DISTANCE_UNITS.MILLIMETER,
  JMAP_SERVER_DISTANCE_UNITS.CENTIMETER,
  JMAP_SERVER_DISTANCE_UNITS.METER,
  JMAP_SERVER_DISTANCE_UNITS.KILOMETER,
  JMAP_SERVER_DISTANCE_UNITS.INCH,
  JMAP_SERVER_DISTANCE_UNITS.FOOT,
  JMAP_SERVER_DISTANCE_UNITS.YARD,
  JMAP_SERVER_DISTANCE_UNITS.MILE,
  JMAP_SERVER_DISTANCE_UNITS.NAUTICAL_MILE
]

export const enum TASK_TYPES {
  FILL = "seed",
  DELETE = "truncate"
}

export const enum TASK_LEVELS {
  ALL = "all-levels",
  SOME = "some-levels"
}

export enum LIST_SORT_DIRECTIONS {
  ASC = "asc",
  DESC = "desc"
}
export const ALL_LIST_SORT_DIRECTIONS: LIST_SORT_DIRECTIONS[] = [LIST_SORT_DIRECTIONS.ASC, LIST_SORT_DIRECTIONS.DESC]

export interface JServerExtent {
  x1: number
  y1: number
  x2: number
  y2: number
}

export interface JProject {
  id: string
  defaultLanguage: LOCALES
  name: JLocaleTranslation
  description: JLocaleTranslation
  createdBy: string
  creationDate: Date
  lastModifiedBy: string
  lastModificationDate: Date
  organizationId: string
  mapCrs: string
  measurementCrs: string
  mapUnit: JMAP_SERVER_DISTANCE_UNITS
  distanceUnit: JMAP_SERVER_DISTANCE_UNITS
  displayUnit: JMAP_SERVER_DISTANCE_UNITS
  initialExtent: JServerExtent | null
  backgroundColor: string
  defaultSelectionColor: string
  rotation: number
  tags: JTag[]
  public: boolean
  layerCount?: number
}

export interface JProjectSubmitValues {
  defaultLanguage: string
  name: JLocaleTranslation
  description: JLocaleTranslation
  mapCrs: string
  mapUnit: JMAP_SERVER_DISTANCE_UNITS
  distanceUnit: JMAP_SERVER_DISTANCE_UNITS
  displayUnit: JMAP_SERVER_DISTANCE_UNITS
  backgroundColor: string
  defaultSelectionColor: string
  rotation: number
  tags?: string[] // tags are only for creation
  public: boolean
}

export interface JProjectRepository {
  get(organizationId: string, page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JProject>>
  getCount(organizationId: string): Promise<number>
  create(organizationId: string, project: JProjectSubmitValues): Promise<string>
  update(organizationId: string, projectId: string, project: JProjectSubmitValues): Promise<void>
  addTag(organizationId: string, projectId: string, tag: string): Promise<void>
  deleteTag(organizationId: string, projectId: string, tagId: string): Promise<void>
  delete(organizationId: string, projectId: string): Promise<void>
  getPermissionACLs(organizationId: string, projectId: string): Promise<JProjectAcl[]>
  getUserPermissions(organizationId: string, projectId: string): Promise<PROJECT_PERMISSIONS[]>
  updatePermissionACLs(organizationId: string, projectId: string, acls: JProjectAcl[]): Promise<void>
  getProjectMetrics(organizationId: string, projectId: string): Promise<any>
  getLayerCount(organizationId: string, projectId: string): Promise<number>
}

export interface JProjectState extends JDataGridState {
  displayCards: boolean
  // filter: string
  // listSort: LIST_SORT_DIRECTIONS
  isFormDialogOpen: boolean
  savedProject: JProject | null
  projectForWhichToEditPermissions: JProject | null
  projectForWhichToManageMvtCache: JProject | null
  isLoading: boolean
}

export enum PROJECT_PERMISSIONS {
  OWNER = "OWNER",
  MODIFY = "MODIFY",
  VIEW = "VIEW"
}

export interface JProjectAcl {
  principal: string
  permissions: PROJECT_PERMISSIONS[]
}
