import MapboxDraw, { MapboxDrawControls } from "@mapbox/mapbox-gl-draw"
import { bbox } from "@turf/turf"
import DrawRectangle from "mapbox-gl-draw-rectangle-mode"
import maplibregl, { IControl, Map as MaplibreMap } from "maplibre-gl"
import { JProject, JServerExtent } from "project/model"
import React from "react"
import { Layer, Map, Source } from "react-map-gl/maplibre"

export interface DataSourceMapProps {
  project: JProject
  setNumOfLoadedFeatures?: (n: number) => void
  setIsLoading?: (isLoading: boolean) => void
  setExtent: (extent: JServerExtent) => void
}
export const SelectAreaMap = (props: DataSourceMapProps) => {
  const [map, setMap] = React.useState<MaplibreMap | null>(null)
  let isDrawing = false
  const [currentExtentId, setCurrentExtentId] = React.useState<string | null>(null)
  let startPos: maplibregl.LngLat | null = null

  // necessary for async calls in useEffects
  const mounted = React.useRef(false)
  React.useEffect(() => {
    mounted.current = true
    return () => {
      mounted.current = false
    }
  }, [])
  const drawRef = React.useRef<MapboxDraw | null>(null)

  React.useEffect(() => {
    if (map && drawRef.current) {
      map.addControl(drawRef.current as unknown as IControl)
    }
  }, [map, drawRef.current])

  React.useEffect(() => {
    drawRef.current = new MapboxDraw({
      displayControlsDefault: false,
      modes,
      styles: extendStyle
    })
  }, [])
  React.useEffect(() => {
    if (map && mounted.current && drawRef.current) {
      map.on("draw.create", createArea)
      map.on("draw.update", updateArea)
      map.on("mousedown", mouseDown)
      map.on("mouseup", mouseUp)

      return () => {
        if (map && drawRef.current) {
          map.removeControl(drawRef.current as unknown as IControl)
          map.off("draw.update", updateArea)
          map.off("draw.create", updateArea)
          map.off("mousedown", updateArea)
        }
      }
    }
  }, [map])

  const mouseUp = () => {
    if (map && drawRef.current && startPos) {
      if (startPos.lat === map.getCenter().lat && startPos.lng === map.getCenter().lng) {
        if (!isDrawing) {
          isDrawing = true
          drawRef.current.changeMode("draw_rectangle")
        } else {
          isDrawing = false
        }
      }
    }
  }
  const createArea = (e: any) => {
    if (map && drawRef.current) {
      const feature = e.features
      if (feature.length > 0) {
        if (currentExtentId) {
          drawRef.current.delete(currentExtentId)
        }
        setCurrentExtentId(feature[0].id)
        const bounds = bbox(feature[0])
        props.setExtent({
          x1: bounds[0],
          y1: bounds[1],
          x2: bounds[2],
          y2: bounds[3]
        })
      }
    }
  }

  const updateArea = (e: any) => {
    // console.log("updateArea")
  }

  const mouseDown = (e: any) => {
    if (map && drawRef.current) {
      startPos = map.getCenter()
      const type = drawRef.current.getMode()
      if (type === "draw_rectangle") {
        const geometryToDel = drawRef.current.getAll().features.filter((f: any) => f.geometry.coordinates[0][0] === null)
        if (geometryToDel.length > 0) {
          drawRef.current.delete(geometryToDel[0].id as string)
        } else if (drawRef.current.getAll().features.length > 1) {
          drawRef.current.delete(drawRef.current.getAll().features[0].id as string)
        }
      }
    }
  }

  React.useEffect(() => {
    if (map) {
      if (props.project.initialExtent) {
        drawPolygon({
          x1: props.project.initialExtent.x1,
          x2: props.project.initialExtent.x2,
          y1: props.project.initialExtent.y1,
          y2: props.project.initialExtent.y2
        })
        map.fitBounds(
          [
            [props.project.initialExtent.x1, props.project.initialExtent.y1],
            [props.project.initialExtent.x2, props.project.initialExtent.y2]
          ],
          { animate: true, padding: 10 }
        )
      }
    }
  }, [map])
  const drawPolygon = (coordinates: { x1: number; x2: number; y1: number; y2: number }) => {
    if (!map || !drawRef.current) {
      return
    }

    const extend: GeoJSON.Feature<GeoJSON.Polygon> = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [coordinates.x1, coordinates.y1],
            [coordinates.x1, coordinates.y2],
            [coordinates.x2, coordinates.y2],
            [coordinates.x2, coordinates.y1],
            [coordinates.x1, coordinates.y1]
          ]
        ]
      }
    }

    drawRef.current.deleteAll().add(extend)
    map.fire("draw.create", { features: [extend] })
  }
  return (
    <Map
      style={{ width: "100%", height: "100%" }}
      id="sds-map"
      mapLib={maplibregl}
      onLoad={e => {
        setMap(e.target)
      }}
    >
      <Source
        id="basemap"
        type="raster"
        tileSize={256}
        maxzoom={19}
        tiles={["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png", "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"]}
      >
        <Layer type="raster" id="basemap" />
      </Source>
    </Map>
  )
}
const modes: any = MapboxDraw.modes
modes.draw_rectangle = DrawRectangle

const extendStyle = [
  {
    id: "gl-draw-polygon-stroke",
    type: "line",
    filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    paint: {
      "line-color": "#15745D",
      "line-width": 5
    }
  }
]
