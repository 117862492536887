import { createTheme, Theme, ThemeOptions } from "@mui/material"
import { enUS as Core_enUS, esES as Core_esES, frFR as Core_frFR } from "@mui/material/locale"
import { enUS as DataGrid_enUS, esES as DataGrid_esES, frFR as DataGrid_frFR } from "@mui/x-data-grid"
import type {} from "@mui/x-data-grid/themeAugmentation"
import { LOCALES } from "app/language/model"
import { store } from "app/store/store"
import { PREF_UI_THEME_DARK } from "ui/model"
import { uiActionCreator } from "ui/store/actions"
import { userSVC } from "user/service"

/**
 * See ./model.ts for custom Palette properties
 */
const lightThemeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      light: "#AECBC4",
      main: "#15745D",
      dark: "#026049"
    },
    secondary: {
      light: "#DADFD9",
      main: "#808E7F",
      dark: "#61765F"
    },
    background: {
      default: "#FFFFFF",
      paper: "#F5F5F5"
    },
    text: {
      primary: "#4B4B4B",
      secondary: "#9B9999",
      disabled: "#C0C0C0"
    },
    info: {
      dark: "#4B4B4B",
      main: "#9B9999",
      light: "#e2e2e2"
    },
    success: {
      light: "#E8FAF4",
      main: "#00B47E",
      dark: "#019468"
    },
    warning: {
      light: "#FFF7E4",
      main: "#FFB100",
      dark: "#E49E01"
    },
    error: {
      light: "#FFF6F6",
      main: "#F13655",
      dark: "#DD1133"
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            boxShadow: "0 4px 12px -4px rgba(0, 0, 0, 0.12)",
            borderColor: "transparent"
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4
      },
      styleOverrides: {
        root: {
          borderRadius: "0.5rem"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "1.25rem"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: "2rem",
          fontWeight: 700,
          fontSize: "1.5rem"
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained" // default for "main action" button, "alternate action" should be outlined
      },
      styleOverrides: {
        root: { textTransform: "none" }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none !important",
          outline: "none !important",
          cursor: "default"
        },
        cell: ({ theme }: { theme: Theme }) => ({
          border: "none !important",
          outline: "none !important",
          color: theme.palette.text.secondary
        }),
        columnSeparator: {
          visibility: "hidden"
        },
        columnHeaderTitle: ({ theme }: { theme: Theme }) => ({
          fontSize: "0.8rem",
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.primary
        })
      },
      defaultProps: {
        density: "compact"
      }
    }
  },
  typography: {
    fontFamily: 'Barlow,"IBM Plex Mono"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  }
}

const darkThemeOptions: ThemeOptions = {
  palette: {
    mode: "dark",
    primary: {
      light: "#17C499",
      main: "#14A984",
      dark: "#119272",
      contrastText: "#FFFFFF"
    },
    secondary: {
      light: "#A7C0A5",
      main: "#749C71",
      dark: "#5D815A"
    },
    background: {
      default: "#161616",
      paper: "#111111"
    },
    text: {
      primary: "#B4C2BB",
      secondary: "#787878",
      disabled: "#474747"
    },
    info: {
      dark: "#4B4B4B",
      main: "#9B9999",
      light: "#e2e2e2"
    },
    success: {
      light: "#E8FAF4",
      main: "#00B47E",
      dark: "#019468"
    },
    warning: {
      light: "#FFF7E4",
      main: "#FFB100",
      dark: "#E49E01"
    },
    error: {
      light: "#FFF6F6",
      main: "#F13655",
      dark: "#DD1133"
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& fieldset": {
            boxShadow: "0 4px 12px -4px rgba(0, 0, 0, 60%)",
            borderColor: "transparent"
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4
      },
      styleOverrides: {
        root: {
          borderRadius: "0.5rem"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "1.25rem"
        }
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingBottom: "2rem",
          fontWeight: 700,
          fontSize: "1.5rem"
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard"
      }
    },
    MuiButton: {
      defaultProps: {
        variant: "contained" // default for "main action" button, "alternate action" should be outlined
      },
      styleOverrides: {
        root: { textTransform: "none" }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none !important",
          outline: "none !important",
          cursor: "default"
        },
        cell: ({ theme }: { theme: Theme }) => ({
          border: "none !important",
          outline: "none !important",
          color: theme.palette.text.secondary
        }),
        columnSeparator: {
          visibility: "hidden"
        },
        columnHeaderTitle: ({ theme }: { theme: Theme }) => ({
          fontSize: "0.8rem",
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.text.primary
        })
      },
      defaultProps: {
        density: "compact"
      }
    }
  },
  typography: {
    fontFamily: 'Barlow,"IBM Plex Mono"',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700
  }
}

const lightThemeFr: Theme = createTheme(lightThemeOptions, Core_frFR, DataGrid_frFR)
const lightThemeEn: Theme = createTheme(lightThemeOptions, Core_enUS, DataGrid_enUS)
const lightThemeEs: Theme = createTheme(lightThemeOptions, Core_esES, DataGrid_esES)
const darkThemeFr: Theme = createTheme(darkThemeOptions, Core_frFR, DataGrid_frFR)
const darkThemeEn: Theme = createTheme(darkThemeOptions, Core_enUS, DataGrid_enUS)
const darkThemeEs: Theme = createTheme(darkThemeOptions, Core_esES, DataGrid_esES)

const defaultThemeFr: Theme = isUserBrowserIsDarkMode() ? darkThemeFr : lightThemeFr
const defaultThemeEn: Theme = isUserBrowserIsDarkMode() ? darkThemeEn : lightThemeEn
const defaultThemeEs: Theme = isUserBrowserIsDarkMode() ? darkThemeEs : lightThemeEs

export const localizedThemes: { [key: string]: { [key: string]: Theme } } = {
  [LOCALES.EN]: {
    dark: darkThemeEn,
    light: lightThemeEn,
    default: defaultThemeEn
  },
  [LOCALES.FR]: {
    dark: darkThemeFr,
    light: lightThemeFr,
    default: defaultThemeFr
  },
  [LOCALES.ES]: {
    dark: darkThemeEs,
    light: lightThemeEs,
    default: defaultThemeEs
  }
}

export function updateTheme(): void {
  const locale = store.getState().language.locale
  store.dispatch(uiActionCreator.setTheme(localizedThemes[locale].default))
}

export function setDarkThemeActive(isDarkThemeActive: boolean): void {
  if (isDarkThemeActive === undefined) {
    isDarkThemeActive = true
  }
  const locale = store.getState().language.locale
  store.dispatch(uiActionCreator.setTheme(isDarkThemeActive ? localizedThemes[locale].dark : localizedThemes[locale].light))
  userSVC.setPreference(PREF_UI_THEME_DARK, isDarkThemeActive ? "true" : "false").catch(reason => {
    console.error("cannot set user preference for theme", reason)
  })
}

export function isUserBrowserIsDarkMode(): boolean {
  return window.matchMedia("(prefers-color-scheme: dark)").matches
}
