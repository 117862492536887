import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Card, CardContent, CircularProgress, Fab, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { languageSVC, translate } from "app/language/service"
import { JRootState } from "app/model"
import { useAppDispatch } from "app/store/hooks"
import { store } from "app/store/store"
import { messageSVC } from "message/service"
import { JOrganization } from "organization/model"
import { JPage, SDS_PATH } from "page/model"
import React from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { DATA_SOURCE_TABS } from "spatialdatasource/model"
import { setActiveTab as setSdsActiveTab } from "spatialdatasource/store"
import { MAIN_LAYOUT_HEADER_MENU_BUTTON_ID, MAIN_LAYOUT_HEADER_MENU_ID, MAIN_TITLE_HEIGHT_IN_REM } from "ui/model"
import { uiSVC } from "ui/service"
import { ChangePasswordFormDialog } from "user/components/ChangePasswordFormDialog"
import { userSVC } from "user/service"
import { selectUserOrganization } from "user/store"
import { logout } from "user/tools/common"

interface JMainHeaderProps {
  activePage: JPage
  isUserLoggedIn: boolean
  userName: string
  userEmail: string
  userOrganizations: JOrganization[]
  userSelectedOrganization: JOrganization
  userPicture: string
  uploadPercentCompletedByFileId: Record<string, number>
  uploadIsPausedByFileId: Record<string, boolean>
  sdsActiveTab: DATA_SOURCE_TABS
}

const MainHeaderFn = (props: JMainHeaderProps): JSX.Element => {
  const theme = useTheme()
  const [languageMenuAnchorElement, setLanguageMenuAnchorElement] = React.useState<null | HTMLElement>(null)
  const handleCloseLanguage = () => setLanguageMenuAnchorElement(null)
  const locales = languageSVC.getLocales()
  const currentLocale = languageSVC.getLocale()
  const [mainMenuAnchorElement, setMainMenuAnchorElement] = React.useState<null | HTMLElement>(null)
  const mainMenuOpen: boolean = Boolean(mainMenuAnchorElement)
  const handleCloseMainMenu = () => setMainMenuAnchorElement(null)
  const [openChangePasswordDialog, setOpenChangePasswordDialog] = React.useState(false)

  const getAvatarInitials = (username: string): string => {
    const pieces = username.split(" ").filter(part => part !== "")
    return (pieces.length === 0 ? "?" : pieces.length === 1 ? (pieces[0].length === 1 ? pieces[0][0] : pieces[0][0] + pieces[0][1]) : pieces[0][0] + pieces[1][0]).toUpperCase()
  }

  const navigate = useNavigate()

  const dispatch = useAppDispatch()

  const nActiveUploads = Object.entries(props.uploadPercentCompletedByFileId).filter(([fileId, perc]) => perc < 100 && !props.uploadIsPausedByFileId[fileId]).length

  const handleUploadFabClick = () => {
    store.dispatch(setSdsActiveTab(DATA_SOURCE_TABS.FILES))
    navigate(SDS_PATH)
  }
  let menuItemIdex = 0

  return (
    <Box
      sx={{
        "width": "100%",
        "height": `${MAIN_TITLE_HEIGHT_IN_REM}rem`,
        "minHeight": `${MAIN_TITLE_HEIGHT_IN_REM}rem`,
        "maxHeight": `${MAIN_TITLE_HEIGHT_IN_REM}rem`,
        "padding": "0",
        "paddingLeft": "1rem",
        "backgroundColor": theme.palette.background.paper,
        "paddingRight": "1rem",
        "display": "flex",
        "alignItems": "center",
        "justifyContent": "space-between",
        "borderBottom": `1px solid ${theme.palette.divider}`,
        "& > p": {
          fontSize: "1.3rem"
        },
        "& .main-layout-header-menu": {
          color: theme.palette.secondary.main
        },
        "& .main-layout-header-menu:hover": {
          color: theme.palette.primary.main,
          cursor: "pointer"
        }
      }}
    >
      <Typography
        sx={{
          marginLeft: "1rem",
          fontSize: "1.5rem",
          fontWeight: "bold"
        }}
        color="textPrimary"
      >
        {translate(props.activePage.titleTranslationKey)}
      </Typography>
      {props.isUserLoggedIn && props.userSelectedOrganization && (
        <Box
          sx={{
            gap: "0.1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          {nActiveUploads > 0 && (
            <Tooltip title={translate("label.current.file.uploads")}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Fab
                  aria-label={translate("label.current.file.uploads")}
                  size="small"
                  style={{ transform: "scale(0.6)" }}
                  color="secondary"
                  sx={{
                    "&:disabled": {
                      backgroundColor: theme.palette.secondary.main,
                      color: theme.palette.getContrastText(theme.palette.secondary.main)
                    }
                  }}
                  disabled={props.activePage.path === SDS_PATH && props.sdsActiveTab === DATA_SOURCE_TABS.FILES}
                  onClick={handleUploadFabClick}
                >
                  {nActiveUploads}
                </Fab>
                <CircularProgress
                  color="secondary"
                  size={30}
                  sx={{
                    position: "absolute",
                    top: 5,
                    left: 5,
                    zIndex: 1
                  }}
                />
              </Box>
            </Tooltip>
          )}
          <IconButton color="secondary" onClick={(event: any) => setLanguageMenuAnchorElement(event.currentTarget)}>
            <FontAwesomeIcon size="sm" style={{ margin: 0, aspectRatio: "1/1" }} icon={regular("language")} />
          </IconButton>
          <IconButton color="secondary" onClick={() => uiSVC.setDarkThemeActive(!uiSVC.isDarkThemeActive())}>
            <FontAwesomeIcon size="sm" style={{ margin: 0 }} icon={uiSVC.isDarkThemeActive() ? light("sun") : light("moon")} />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              [`& #${MAIN_LAYOUT_HEADER_MENU_BUTTON_ID}`]: {
                marginLeft: ".5rem"
              }
            }}
          >
            <IconButton
              onClick={event => {
                setMainMenuAnchorElement(event.currentTarget)
              }}
            >
              <Avatar
                sx={{
                  height: "2rem",
                  width: "2rem",
                  fontSize: "1.0rem",
                  backgroundColor: theme.palette.secondary.main
                }}
              >
                {getAvatarInitials(props.userName)}
              </Avatar>
            </IconButton>
            <Menu
              id={MAIN_LAYOUT_HEADER_MENU_ID}
              anchorEl={mainMenuAnchorElement}
              sx={{ ".MuiList-root": { paddingTop: 0 } }}
              open={mainMenuOpen}
              onClose={handleCloseMainMenu}
              MenuListProps={{
                "aria-labelledby": MAIN_LAYOUT_HEADER_MENU_BUTTON_ID
              }}
            >
              <Card elevation={4} sx={{ marginBottom: "0.5rem", borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                <CardContent sx={{ ":last-child": { paddingBottom: "0.5rem", paddingTop: 0 } }}>
                  <Typography color="textPrimary" sx={{ fontSize: "1.125rem", fontWeight: "bold" }}>
                    {props.userSelectedOrganization.name}
                  </Typography>
                  <Typography color="textPrimary" sx={{ fontSize: "1rem" }}>
                    {props.userName}
                  </Typography>
                  <Typography color="textPrimary" sx={{ fontSize: "1rem" }}>
                    {props.userEmail}
                  </Typography>
                </CardContent>
              </Card>
              {props.userOrganizations.length > 1 && (
                <MenuItem key={"switch-org-menu-item"} onClick={() => dispatch(selectUserOrganization(null))}>
                  {translate("login.change.organization.title")}
                </MenuItem>
              )}
              <MenuItem
                key={`menuItem${menuItemIdex++}`}
                onClick={() => {
                  setOpenChangePasswordDialog(true)
                  handleCloseMainMenu()
                }}
              >
                {translate("user.password.menu.label")}
              </MenuItem>
              <MenuItem key={`menuItem${menuItemIdex++}`} onClick={() => logout()}>
                {translate("logout.title")}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      )}
      <Menu id="jmap-locales-menu" open={Boolean(languageMenuAnchorElement)} anchorEl={languageMenuAnchorElement} onClose={() => handleCloseLanguage()} variant="menu">
        {locales.map((locale, index) => (
          <MenuItem
            sx={{ paddingLeft: "0.5rem" }}
            key={`jmap-locales-menu-item-${index}`}
            selected={currentLocale === locale}
            onClick={() => {
              if (locale !== currentLocale) {
                if (userSVC.isLoggedIn()) {
                  messageSVC.confirmDialog({
                    isCancelDefault: true,
                    confirmButtonLabel: translate("button.yes", undefined, locale),
                    cancelButtonLabel: translate("button.no", undefined, locale),
                    message: translate("user.change.locale.warning", undefined, locale),
                    title: translate("user.change.locale.title", undefined, locale),
                    locale,
                    onSuccess: () => languageSVC.setLocale(locale)
                  })
                } else {
                  languageSVC.setLocale(locale)
                }
              }
              handleCloseLanguage()
            }}
          >
            <ListItemIcon sx={{ minWidth: "1.5rem" }}>{currentLocale === locale && <FontAwesomeIcon icon={light("check")} />}</ListItemIcon>
            {translate(`user.locale.${locale}`, undefined, locale)}
          </MenuItem>
        ))}
      </Menu>
      {openChangePasswordDialog && (
        <ChangePasswordFormDialog
          close={() => {
            setOpenChangePasswordDialog(false)
          }}
          afterSubmit={() => {
            setOpenChangePasswordDialog(false)
          }}
        />
      )}
    </Box>
  )
}

export const MainHeader = connect(
  (state: JRootState) =>
    ({
      activePage: state.page.activePage,
      isUserLoggedIn: state.user.isUserLoggedIn,
      userName: state.user.name,
      userEmail: state.user.email,
      userPicture: state.user.picture,
      userSelectedOrganization: state.user.selectedOrganization,
      userOrganizations: state.user.organizations,
      uploadPercentCompletedByFileId: state.file.uploadPercentCompletedByFileId,
      uploadIsPausedByFileId: state.file.uploadIsPausedByFileId,
      sdsActiveTab: state.sds.activeTab
    } as JMainHeaderProps)
)(MainHeaderFn)
