import { ACTIONS } from "app/store/actions"
import { JOrganizationState } from "organization/model"
import { Action, Reducer } from "redux"
import { JOrganizationActionSetActiveTab } from "./actions"
import { getDefaultOrganizationState } from "./state"

export const organizationReducer: Reducer<JOrganizationState, Action> = (state: JOrganizationState | undefined, action: Action): JOrganizationState => {
  if (!state) {
    state = getDefaultOrganizationState()
  }
  switch (action.type) {
    case ACTIONS.ORGANIZATION_SET_ACTIVE_TAB: {
      const { activeTab } = action as JOrganizationActionSetActiveTab
      return {
        ...state,
        activeTab
      }
    }

    default:
      return state
  }
}
