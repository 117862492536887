import { translate } from "app/language/service"
import { store } from "app/store/store"
import { DASHBOARD_PATH, JPage } from "page/model"
import { pageActionCreator } from "page/store/actions"
import { getAllPages } from "./start"

export function getActivePage(): JPage {
  return store.getState().page.activePage
}

export function getActivePageTitle(): string {
  return translate(store.getState().page.activePage.titleTranslationKey)
}

export function refreshPages(): void {
  store.dispatch(pageActionCreator.setAll(getAllPages()))
}

export function getPageByPath(pagePath: string): JPage | null {
  const allPages: JPage[] = store.getState().page.pages
  if (pagePath.endsWith(".html") || pagePath.endsWith(".jsp") || pagePath.endsWith(".asp")) {
    pagePath = DASHBOARD_PATH
  }
  for (const page of allPages) {
    const path = page.path
    const index = path.indexOf("/:")
    if (index === -1) {
      if (pagePath === path) {
        return page
      }
    } else {
      const beginningPath: string = path.substring(0, index + 1)
      if (pagePath.startsWith(beginningPath)) {
        return page
      }
    }
  }
  return null
}

// warning this page doesn't redirect to the page, only set the page in the store
export function activatePageByPathInStore(pagePath: string): void {
  const page = getPageByPath(pagePath) // throw if not found
  if (!page) {
    return
  }
  if (store.getState().page.activePage.path === pagePath) {
    // already active
    return
  }
  store.dispatch(pageActionCreator.activate(page))
}
