import { Button, DialogActions, DialogContent, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import { translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { JProject } from "project/model"
import { setProjectMvtCacheDialog } from "project/store"
import { getProjectMetrics } from "project/utils"
import React from "react"

interface ProjectMvtTileCacheDialogStatusProps {
  project: JProject
}
export const ProjectMvtTileCacheDialogStatus = ({ project }: ProjectMvtTileCacheDialogStatusProps) => {
  const [totalVolume, setTotalVolume] = React.useState(0)
  const [totalTiles, setTotalTiles] = React.useState(0)
  const [levels, setLevels] = React.useState<number[]>([])
  const theme = useTheme()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    getProjectMetrics(project.id)
      .then(metrics => {
        setLevels(metrics)
        let volume = 0
        let tiles = 0
        console.log("metrics", metrics)
        Object.keys(metrics).forEach((key: any) => {
          volume += metrics[key].totalDataSize
          tiles += metrics[key].numberOfTiles
        })
        // metrics.forEach((m: any) => {
        //   volume += m.volume
        //   tiles += m.tiles
        // })
        setTotalVolume(volume)
        setTotalTiles(tiles)
        setLevels(metrics)
      })
      .catch(error => {
        console.error(error)
      })
  }, [project])

  return (
    <>
      <DialogContent sx={{ padding: "40px 0", borderBottom: "3px solid ", borderColor: `${theme.palette.grey[300]}` }}>
        <Grid container gap={5} display="flex" direction="row" alignItems="start">
          <DataDisplay data={`${totalVolume} Mb`} label="Total Volume" />
          <DataDisplay data={`${totalTiles}`} label="Cached Tiles" />
          <TilesTable levels={levels} />
        </Grid>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "flex-end" }}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Button
            variant="outlined"
            onClick={() => {
              dispatch(setProjectMvtCacheDialog(null))
            }}
          >
            {translate("button.cancel")}
          </Button>
        </Stack>
      </DialogActions>
    </>
  )
}

interface DataDisplayProps {
  data: string
  label: string
}
const DataDisplay = ({ data, label }: DataDisplayProps) => {
  const theme = useTheme()
  return (
    <Grid item sx={{ border: "1px solid gray", borderRadius: "10px", aspectRatio: "1", flex: "1" }}>
      <Grid xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", margin: "auto", height: "100%" }}>
        <Typography variant="h4" sx={{ color: `${theme.palette.primary.dark}`, fontWeight: "bold" }}>
          {data}
        </Typography>
        <Typography variant="h6">{label}</Typography>
      </Grid>
    </Grid>
  )
}

interface tileTableProps {
  levels: any
}
const TilesTable = ({ levels }: tileTableProps) => {
  const theme = useTheme()
  return (
    <Grid item sx={{ flex: "2", maxHeight: "400px", overflow: "scroll" }}>
      <TableContainer>
        <Table sx={{ minWidth: 200, maxHeight: "400px" }} aria-label="simple table">
          <TableHead sx={{ backgroundColor: `${theme.palette.background.paper}` }}>
            <TableRow>
              <TableCell>Levels</TableCell>
              <TableCell align="right">Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(levels).map(level => (
              <TableRow key={level} sx={{ "&:last-child td, &:last-child th": { border: 0 }, "padding": "0" }}>
                <TableCell component="th" scope="row">
                  Level {level}
                </TableCell>
                <TableCell align="right">{levels[level].numberOfTiles} Tiles</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  )
}
