import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material"
import { Box } from "@mui/system"
import { translate } from "app/language/service"
import { JVectorFileInfoLayerIndexedAttribute } from "file/model"
import { DataSourceMap } from "map/components/DataSourceMap"
import { useTags } from "organization/hooks"
import { JTag } from "organization/model"
import React from "react"
import { DATA_SOURCE_PERMISSIONS, DATA_SOURCE_TYPES, JAttribute, JDataSource, JDataSourceInlineUpdateSubmitValues } from "spatialdatasource/model"
import { sdsRPO } from "spatialdatasource/repository"
import { getUserDataSourcePermissions, updateDataSource, updateDataSourceTags } from "spatialdatasource/utils"
import { InlineEditableAttributesTable } from "ui/components/InlineEditableAttributesTable"
import { InlineEditableAutocomplete } from "ui/components/InlineEditableAutocomplete"
import { InlineEditableTextField } from "ui/components/InlineEditableTextField"
import { RichTextLabel } from "ui/components/RichTextLabel"
import { RichTextLabelAdornmentWithCopyButton } from "ui/components/RichTextLabelAdornmentWithCopyButton"
import { StatusChip } from "ui/components/StatusChip"
import { TextLabel } from "ui/components/TextLabel"
import { STATUS_CHIP_LEVELS } from "ui/model"

interface JDataSourceDetailsDialogProps {
  dataSource: JDataSource
  onClose: () => any
}

export const DataSourceDetailsDialog = (props: JDataSourceDetailsDialogProps) => {
  const [dataSource, setDataSource] = React.useState(props.dataSource)
  const [mapIsLoading, setMapIsLoading] = React.useState(true)
  const [numOfLoadedMapFeatures, setNumOfLoadedMapFeatures] = React.useState(0)
  const [sdsFeatureCount, setSdsFeatureCount] = React.useState(0)
  const [userCanModify, setUserCanModify] = React.useState(false)
  const [indexedAttributesTablesIsEditable, setIndexedAttributesTablesIsEditable] = React.useState(false)
  const inlineEditableAttributesTableRef = React.useRef<HTMLDivElement | null>(null)
  const existingTags = useTags()
  React.useEffect(() => {
    if (dataSource.type === DATA_SOURCE_TYPES.FILE) {
      sdsRPO.getFeatureCount(dataSource.organizationId, dataSource.id).then(setSdsFeatureCount)
    }
  }, [])

  React.useEffect(() => {
    if (!dataSource) {
      return
    }
    getUserDataSourcePermissions(dataSource.id).then(permissions => {
      setUserCanModify(permissions.includes(DATA_SOURCE_PERMISSIONS.MODIFY))
    })
  }, [dataSource])

  const TagField = (
    <InlineEditableAutocomplete
      disabled={!userCanModify}
      onAcceptEdit={async (tagNames: string[]) => {
        const tags = await updateDataSourceTags(dataSource, tagNames)
        setDataSource({
          ...dataSource,
          tags
        })
      }}
      options={existingTags.map(t => t.name)}
      label={translate("label.tags")}
      DisplayComponent={RichTextLabel}
      DisplayComponentProps={{
        label: translate("label.tags"),
        value: (
          <Box sx={{ marginTop: "0.6rem", display: "flex", flexWrap: "wrap", gap: "0.5rem" }}>
            {dataSource.tags.map((tag: JTag) => (
              <StatusChip key={tag.id} level={STATUS_CHIP_LEVELS.NEUTRAL} label={tag.name} />
            ))}
          </Box>
        )
      }}
      initialValue={dataSource.tags.map(t => t.name)}
    ></InlineEditableAutocomplete>
  )

  const cancelIndexedAttributesTableEdit = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (inlineEditableAttributesTableRef.current && !inlineEditableAttributesTableRef.current.contains(e.target as Node)) {
      if (indexedAttributesTablesIsEditable) {
        setIndexedAttributesTablesIsEditable(false)
      }
      return
    }
    if (!indexedAttributesTablesIsEditable && userCanModify) {
      setIndexedAttributesTablesIsEditable(true)
    }
  }

  return (
    <Dialog open fullWidth maxWidth="lg" onClick={e => cancelIndexedAttributesTableEdit(e)}>
      <DialogTitle>
        <InlineEditableTextField
          disabled={!userCanModify}
          onAcceptEdit={(name: string) => {
            const updateValues: JDataSourceInlineUpdateSubmitValues = { id: dataSource.id, name }
            updateDataSource(updateValues)
            setDataSource({
              ...dataSource,
              name
            })
          }}
          DisplayComponent={Box}
          DisplayComponentChild={dataSource.name}
          initialValue={dataSource.name}
          required={false}
        ></InlineEditableTextField>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} minHeight={"325px"} position={"relative"}>
            <DataSourceMap dataSource={dataSource} setIsLoading={setMapIsLoading} setNumOfLoadedFeatures={setNumOfLoadedMapFeatures} />
          </Grid>
          {/* left column */}
          <Grid item xs={6}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RichTextLabel value={<RichTextLabelAdornmentWithCopyButton value={dataSource.id} name={translate("label.id")} />} label={translate("label.id")} />
              </Grid>
              {dataSource.type === DATA_SOURCE_TYPES.FILE && (
                <>
                  <Grid item xs={12}>
                    <TextLabel label={translate("label.crs")} value={dataSource.crs} />
                  </Grid>
                  <Grid item xs={12}>
                    <RichTextLabel
                      label={translate("label.feature.count")}
                      value={
                        <>
                          <Box marginRight={"0.5rem"}>
                            {translate("map.num.of.features", {
                              numOfLoadedFeatures: numOfLoadedMapFeatures,
                              featureCount: sdsFeatureCount
                            })}
                          </Box>
                          {mapIsLoading && <CircularProgress size={"0.75rem"} />}
                        </>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {TagField}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          {/* right column */}
          <Grid item xs={6}>
            {dataSource.type === DATA_SOURCE_TYPES.RASTER && (
              <Grid item xs={12}>
                <TextLabel label={translate("label.crs")} value={dataSource.crs} />
              </Grid>
            )}
            {dataSource.type === DATA_SOURCE_TYPES.FILE && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div ref={inlineEditableAttributesTableRef}>
                    <InlineEditableAttributesTable
                      disabled={!userCanModify}
                      attributes={dataSource.attributes}
                      isEditable={indexedAttributesTablesIsEditable}
                      setIsEditable={(isEditable: boolean) => setIndexedAttributesTablesIsEditable(isEditable)}
                      onAcceptEdit={(attributes: JAttribute[]) => {
                        const indexedAttributes: JVectorFileInfoLayerIndexedAttribute[] = attributes.map((attr: JVectorFileInfoLayerIndexedAttribute) => ({ name: attr.name, indexed: attr.indexed }))
                        updateDataSource({ id: dataSource.id, indexedAttributes })
                        const newDataSource: JDataSource = {
                          ...dataSource,
                          indexedAttributes,
                          attributes
                        }
                        setDataSource(newDataSource)
                      }}
                    ></InlineEditableAttributesTable>
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <InlineEditableAutocomplete
                    disabled={!userCanModify}
                    onAcceptEdit={(idAttributeName: string) => {
                      const updateValues: JDataSourceInlineUpdateSubmitValues = { id: dataSource.id, idAttributeName }
                      updateDataSource(updateValues)
                      const idAttribute = dataSource.attributes.find(a => a.name === idAttributeName)
                      setDataSource({
                        ...dataSource,
                        idAttribute
                      })
                    }}
                    options={[""].concat(dataSource.attributes.map(a => a.name))}
                    label={translate("label.uniqueIdentifier")}
                    DisplayComponent={TextLabel}
                    DisplayComponentProps={{
                      label: translate("label.uniqueIdentifier"),
                      value: dataSource.idAttribute?.name ?? ""
                    }}
                    initialValue={dataSource.idAttribute?.name ?? ""}
                  ></InlineEditableAutocomplete>
                </Grid>
              </Grid>
            )}
          </Grid>
          {dataSource.type === DATA_SOURCE_TYPES.WMS_WMTS && (
            <Grid item xs={12}>
              <Grid item xs={12}>
                <TextLabel label={translate("sds.wms.wmts.get.capabilities.url")} value={dataSource.capabilitiesUrl} />
              </Grid>
            </Grid>
          )}
          {[DATA_SOURCE_TYPES.RASTER, DATA_SOURCE_TYPES.WMS_WMTS].includes(dataSource.type) && (
            <Grid item xs={12}>
              {TagField}
            </Grid>
          )}
          <Grid item xs={12}>
            <InlineEditableTextField
              disabled={!userCanModify}
              onAcceptEdit={(description: string) => {
                const updateValues: JDataSourceInlineUpdateSubmitValues = { id: dataSource.id, description }
                updateDataSource(updateValues)
                setDataSource({
                  ...dataSource,
                  description
                })
              }}
              TextFieldProps={{ label: translate("label.description") }}
              multiline
              DisplayComponent={TextLabel}
              DisplayComponentProps={{ value: dataSource.description, label: translate("label.description"), multiline: true }}
              initialValue={dataSource.description}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>{translate("button.close")}</Button>
      </DialogActions>
    </Dialog>
  )
}
