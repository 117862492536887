import { historySVC } from "app/history/service"
import { LOCALES, PARAM_OVERRIDEN_LOCALE } from "./model"
import { isValidLocale } from "./tools/common"

export function getOverridenLocaleFromUrlHash(): LOCALES | undefined {
  historySVC.transformSearchParamsIntoHashParams([PARAM_OVERRIDEN_LOCALE])
  const overridenLocale = historySVC.getHashParameter(PARAM_OVERRIDEN_LOCALE) as LOCALES
  if (overridenLocale && isValidLocale(overridenLocale)) {
    return overridenLocale
  }
}
