import { LinearProgress } from "@mui/material"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import React from "react"

export const PortalDataGrid = (props: DataGridProps & { rowType: string }) => (
  <DataGrid
    {...props}
    sx={{
      "width": "100%",
      "height": "100%",
      "& *": {
        userSelect: "none"
      }
    }}
    disableSelectionOnClick
    disableColumnSelector
    localeText={{ noRowsLabel: translate(`${props.rowType}.grid.no.rows.label`) }}
    hideFooterSelectedRowCount
    sortingOrder={["asc", "desc"]}
    components={{
      LoadingOverlay: LinearProgress
    }}
  ></DataGrid>
)
