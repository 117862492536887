import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { feature } from "@turf/turf"
import { ajaxSVC } from "app/ajax/service"
import { getDateFromISOString } from "app/tool/date/tools/common"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { JDataGridPagedResponse, getFormattedGridQueryForJMC } from "ui/tools/grid"
import { JProject, JProjectAcl, JProjectRepository, JProjectSubmitValues, JServerExtent, PROJECT_PERMISSIONS } from "./model"

const projectRPO: JProjectRepository = {
  get(organizationId: string, page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JProject>> {
    return new Promise<JDataGridPagedResponse<JProject>>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects`,
            params: {
              page: page + 1, // watch out API's page is 1-indexed
              size,
              q: getFormattedGridQueryForJMC(filterModel, sortModel)
            }
          })
          .then((response: any) => {
            const apiResponse = {
              page: response.page,
              result: response.result.map((p: any) => getProjectFromRaw(p))
            }
            resolve(apiResponse)
          })
          .catch((error: any) => {
            console.error(`Server error while getting all projects of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting all projects of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  getCount(organizationId: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects?page=1&size=1`
          })
          .then((response: any) => resolve(response.page.totalElements))
          .catch((error: any) => {
            console.error(`Server error while getting project count of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting project counts of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  create(organizationId: string, project: JProjectSubmitValues): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects`,
            params: project
          })
          .then((response: any) => resolve(response.result.id))
          .catch((error: any) => {
            console.error("Server error while creating project", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Unexpected error while getting all projects", ex)
        reject(ex)
      }
    })
  },
  update(organizationId: string, projectId: string, project: JProjectSubmitValues): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .patch({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}`,
            params: project
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while updating project "${projectId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while updating project "${projectId}"`, ex)
        reject(ex)
      }
    })
  },
  addTag(organizationId: string, projectId: string, tag: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}/tags`,
            params: { tag }
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while adding tag to project "${projectId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while adding tag to project "${projectId}"`, ex)
        reject(ex)
      }
    })
  },
  deleteTag(organizationId: string, projectId: string, tagId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}/tags/${tagId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while deleting tag from project "${projectId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while adding tag to project "${projectId}"`, ex)
        reject(ex)
      }
    })
  },
  delete(organizationId: string, projectId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while deleting project "${projectId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while deleting project "${projectId}"`, ex)
        reject(ex)
      }
    })
  },
  getPermissionACLs(organizationId: string, projectId: string): Promise<JProjectAcl[]> {
    return new Promise<JProjectAcl[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}/permissions`
          })
          .then((response: any) => resolve(response.result))
          .catch((error: any) => {
            console.error("Error while getting project ACLs from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting project permissions", ex)
        reject(ex)
      }
    })
  },
  getUserPermissions(organizationId: string, projectId: string): Promise<PROJECT_PERMISSIONS[]> {
    return new Promise<PROJECT_PERMISSIONS[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}/permissions/self`
          })
          .then((response: any) => resolve(response.result[0].permissions))
          .catch((error: any) => {
            console.error("Error while getting my project ACLs from server", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Error while getting user project permissions", ex)
        reject(ex)
      }
    })
  },
  updatePermissionACLs(organizationId: string, projectId: string, acls: JProjectAcl[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .put({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/projects/${projectId}/permissions`,
            params: acls
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while updating ACLs for project "${projectId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while updating ACLs for project "${projectId}"`, ex)
        reject(ex)
      }
    })
  },
  async getLayerCount(organizationId: string, projectId: string): Promise<number> {
    const resp = await ajaxSVC.get({
      url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/metrics/projects/${projectId}`
    })
    return resp.result.layerCount
  },
  getProjectMetrics(organizationId: string, projectId: string): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.MVT_CACHE)}/rest/v1/organizations/${organizationId}/projects/${projectId}/metrics`
          })
          .then((response: any) => resolve(response.result))
          .catch((error: any) => {
            console.error(`Server error while getting metrics for project "${projectId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting metrics for project "${projectId}"`, ex)
        reject(ex)
      }
    })
  }
}

function getProjectFromRaw(rawProject: any): JProject {
  return {
    ...rawProject,
    defaultLanguage: rawProject.defaultLanguage.toLowerCase(), // just to make sure, because of the API
    description: rawProject.description ?? {},
    creationDate: getDateFromISOString(rawProject.creationDate),
    lastModificationDate: getDateFromISOString(rawProject.lastModificationDate),
    initialExtent: rawProject.initialExtent && typeof rawProject.initialExtent === "string" ? convertPolygonWkt(rawProject.initialExtent) : null,
    tags: Array.isArray(rawProject.tags) ? rawProject.tags : []
  }
}

// ex: "POLYGON ((30 10, 40 40, 20 40, 10 20, 30 10))"
function convertPolygonWkt(polygonWkt: string): JServerExtent | null {
  const values = polygonWkt.replace("POLYGON ((", "").replace("))", "").split(", ")
  if (values.length !== 5) {
    return null
  }
  const swPoint = values[0].split(" ")
  const nePoint = values[2].split(" ")
  return {
    x1: Number(swPoint[0]),
    x2: Number(nePoint[0]),
    y1: Number(swPoint[1]),
    y2: Number(nePoint[1])
  }
}

export default projectRPO
