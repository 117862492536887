import { Box, Typography } from "@mui/material"
import { translate } from "app/language/service"
import { useAppSelector } from "app/store/hooks"
import { JProject } from "project/model"
import { getProjectLayerCounts, getProjects } from "project/utils"
import React from "react"
import { ProjectCardItem } from "./ProjectCardItem"

export const ProjectCards = (): JSX.Element => {
  const [projects, setProjects] = React.useState<JProject[]>([])

  const { reloadCounter } = useAppSelector(state => ({
    reloadCounter: state.project.reloadCounter
  }))

  React.useEffect(() => {
    getProjects(0, 25, [{ field: "lastModificationDate", sort: "desc" }], { items: [] })
      .then(resp => {
        const projectsWithoutLayerCounts = resp.result
        setProjects(projectsWithoutLayerCounts)
        getProjectLayerCounts(projectsWithoutLayerCounts)
          .then(projectsWithLayerCounts => {
            setProjects(projectsWithLayerCounts)
          })
          .catch(error => {
            console.error(error)
          })
      })
      .catch(error => {
        console.error(error)
      })
  }, [reloadCounter])

  return (
    <Box
      sx={{
        "width": "100%",
        "maxHeight": "100%",
        "overflow": "auto",
        "padding": 0,
        "paddingBottom": "1rem",
        "paddingTop": "1.5rem",
        "display": "flex",
        "alignItems": "center",
        "flexWrap": "wrap",
        "& > *": {
          marginRight: "1.5rem",
          marginBottom: "1rem"
        }
      }}
    >
      {projects.map(p => (
        <ProjectCardItem project={p} key={p.id} />
      ))}
      {projects.length === 0 && <Typography>{translate("project.grid.no.rows.label")}</Typography>}
    </Box>
  )
}
