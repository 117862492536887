import { App } from "app/components/App"
import { appEventModule } from "app/event"
import { languageSVC } from "app/language/service"
import { startAppListeners } from "app/listeners"
import { APP_CONTAINER_ID, APP_EVENTS } from "app/model"
import { toolSVC } from "app/tool/service"
import { messageSVC } from "message/service"
import { organizationSVC } from "organization/service"
import { pageSVC } from "page/service"
import React from "react"
import ReactDOM from "react-dom"
import { serverSVC } from "server/service"
import { initServerConnection } from "server/tools/common"
import { uiSVC } from "ui/service"
import { userSVC } from "user/service"
import { clearAuth0Session, initAuth0Client } from "user/tools/auth0"
import { initSessionFromToken } from "user/tools/common"
import { willRedirectToAuth0Invitation } from "./auth0"

export async function startApp() {
  if (await willRedirectToAuth0Invitation()) {
    // make sure we invalidate the session otherwise we will not use the nvitation's
    // identity when we come back from Auth0
    await initServerConnection()
    await initAuth0Client()
    await clearAuth0Session()
    return
  }

  const appContainer = document.createElement("div")
  appContainer.id = APP_CONTAINER_ID
  document.body.prepend(appContainer)
  ReactDOM.render(<App />, appContainer)
  startAppListeners()
  initServerConnection().then(() => {
    initSessionFromToken()
      .catch(reason => console.error("Could not init session from token", reason))
      .finally(() => appEventModule.notify(APP_EVENTS.APP_READY))
  })

  // TODO: revert always exposing the API once initial dev is done
  // https://k2geospatial.atlassian.net/browse/JMAP8-1090
  // if (IS_DEV) {
  // used to test
  const win: any = window
  win.JMap = {}
  win.JMap.Portal = {
    Language: languageSVC,
    Message: messageSVC,
    Page: pageSVC,
    Organization: organizationSVC,
    User: userSVC,
    UI: uiSVC,
    Server: serverSVC,
    Tool: toolSVC
  }
  // }
}
