import { light, regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material"
import { Box } from "@mui/system"
import { GridSelectionModel } from "@mui/x-data-grid"
import { translate } from "app/language/service"
import { useAppDispatch } from "app/store/hooks"
import { store } from "app/store/store"
import { JFileInfo } from "file/model"
import { deleteUpload, reload } from "file/store"
import { ALL_ACCEPTED_UPLOAD_MIME_TYPES, deleteFile } from "file/utils"
import { messageSVC } from "message/service"
import React from "react"
import { useDropzone } from "react-dropzone"
import { FileGrid } from "./FileGrid"
import { FileInfoDialog } from "./FileInfoDialog"

interface JFilePanelProps {
  setCurrentFileInfos: (fileInfos: JFileInfo[]) => void
}

export const dropzoneActiveStyle = {
  borderWidth: 2,
  borderRadius: 8,
  borderStyle: "dashed",
  borderColor: "#c0c0c0",
  outline: "none"
}

export const dropzoneAcceptStyle = {
  borderColor: "#00e676"
}

export const dropzoneRejectStyle = {
  borderColor: "#ff1744"
}

// These are only applicable in the "Files" tab (there are different values for the SDS creation dialog)
export const PARALLEL_FILE_UPLOAD_MAX_SIZE_IN_GB = 15
export const PARALLEL_FILE_UPLOAD_MAX_COUNT = 3

export const FilePanel = (props: JFilePanelProps) => {
  const theme = useTheme()
  const [fileInfoToDisplay, setFileInfoToDisplay] = React.useState<JFileInfo | null>(null)
  const [selectedFileIds, setSelectedFileIds] = React.useState<GridSelectionModel>([])
  const dispatch = useAppDispatch()
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: true,
    maxFiles: PARALLEL_FILE_UPLOAD_MAX_COUNT,
    maxSize: PARALLEL_FILE_UPLOAD_MAX_SIZE_IN_GB * 1e9, // in bytes
    accept: ALL_ACCEPTED_UPLOAD_MIME_TYPES,
    onDropRejected: fileRejections => {
      fileRejections.forEach(fr => {
        fr.errors.forEach(err => {
          if (err.code === "too-many-files") {
            messageSVC.error(
              translate("sds.upload.file.reject.too.many.files.parallel", {
                filename: fr.file.name,
                fileUploadMaxCount: PARALLEL_FILE_UPLOAD_MAX_COUNT
              })
            )
          } else if (err.code === "file-invalid-type") {
            messageSVC.error(translate("sds.upload.file.reject.invalid.type", { fileType: fr.file.name }))
          } else if (err.code === "file-too-large") {
            messageSVC.error(
              translate("sds.upload.file.reject.too.large", {
                filename: fr.file.name,
                uploadMaxSize: PARALLEL_FILE_UPLOAD_MAX_SIZE_IN_GB
              })
            )
          }
        })
      })
    }
  })

  const deleteSelectedFiles = () => {
    messageSVC.confirmDialog({
      confirmButtonLabel: translate("button.delete"),
      cancelButtonLabel: translate("button.cancel"),
      isCancelDefault: true,
      title: translate("sds.file.delete.title", { numFiles: selectedFileIds.length }),
      message: translate("sds.file.delete.message", {
        numFiles: selectedFileIds.length
      }),
      onSuccess: () => {
        const promises = selectedFileIds.map(fileId => deleteFile(fileId as string))
        Promise.all(promises)
          .then(() => {
            dispatch(reload())
            selectedFileIds.map(fileId => store.dispatch(deleteUpload(fileId as string)))
          })
          .catch(error => {
            console.error(error)
          })
      }
    })
  }

  const dropzoneStyle = React.useMemo(
    () => ({
      ...{ height: "100%" },
      ...(isDragAccept ? { ...dropzoneActiveStyle, ...dropzoneAcceptStyle } : {}),
      ...(isDragReject ? { ...dropzoneActiveStyle, ...dropzoneRejectStyle } : {})
    }),
    [isDragAccept, isDragReject]
  )

  return (
    // Main vertical container
    <Stack spacing={2} height="100%">
      {/* Top horizontal container, below tabs, with widgets on left and create button on right */}
      <Stack direction="row" justifyContent="space-between">
        {/* Widgets on left */}
        <Stack direction="row" spacing={2} alignItems="center">
          <IconButton sx={{ marginLeft: "0.70rem" }} color="primary" size="small" onClick={() => dispatch(reload())}>
            <FontAwesomeIcon icon={light("refresh")} />
          </IconButton>
          {selectedFileIds.length > 0 && (
            <>
              <Typography color={theme.palette.text.primary}>
                {translate("sds.file.selected.count", {
                  numFiles: selectedFileIds.length
                })}
              </Typography>
              <Button onClick={deleteSelectedFiles}>{translate("label.delete")}</Button>
            </>
          )}
        </Stack>
        <Button startIcon={<FontAwesomeIcon className="create-button" icon={regular("arrow-up")} />} component="label" onClick={open} sx={{ minWidth: "10rem" }}>
          {translate("sds.upload.file")}
        </Button>
      </Stack>

      {fileInfoToDisplay && <FileInfoDialog fileInfo={fileInfoToDisplay} onClose={() => setFileInfoToDisplay(null)} />}

      <Box {...getRootProps({ style: dropzoneStyle })}>
        <input {...getInputProps()} />
        <FileGrid {...props} {...{ acceptedFiles, selectedFileIds, setSelectedFileIds, setFileInfoToDisplay }} />
      </Box>
    </Stack>
  )
}
