import { ACTIONS } from "app/store/actions"
import { ORGANIZATION_TABS } from "organization/model"
import { Action } from "redux"

export interface JOrganizationActionSetActiveTab extends Action {
  activeTab: ORGANIZATION_TABS
}

export const organizationActionCreator = {
  setActiveTab(activeTab: ORGANIZATION_TABS) {
    return {
      type: ACTIONS.ORGANIZATION_SET_ACTIVE_TAB,
      activeTab
    }
  }
}
