import { getOverridenLocaleFromUrlHash } from "../history"
import { LOCALES } from "../model"
import { isValidLocale } from "./common"

export function getOverridenLocaleFromUrlOrOptions(): LOCALES | undefined {
  const options: JMapOptions | undefined = window.JMAP_OPTIONS
  const overridenLocale: LOCALES | undefined = getOverridenLocaleFromUrlHash()
  if (overridenLocale) {
    return overridenLocale
  } else if (options?.locale && isValidLocale(options.locale as LOCALES)) {
    return options.locale as LOCALES
  }
}
