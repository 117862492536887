import { GridFilterModel, GridSortModel } from "@mui/x-data-grid"
import { ajaxSVC } from "app/ajax/service"
import { getDateFromISOString } from "app/tool/date/tools/common"
import { JTag } from "organization/model"
import { JSERVER_MICRO_SERVICE_IDS } from "server/model"
import { getMicroServiceBaseUrlById } from "server/tools/common"
import { JDataGridPagedResponse, getFormattedGridQueryForJMC } from "ui/tools/grid"
import { DATA_SOURCE_PERMISSIONS, DATA_SOURCE_TYPES, JDataSource, JDataSourceAcl, JDataSourceRepository, JDataSourceSubmitValues, JMapBoxBounds } from "./model"

export const sdsRPO: JDataSourceRepository = {
  get(organizationId: string, page: number, size: number, sortModel: GridSortModel, filterModel: GridFilterModel): Promise<JDataGridPagedResponse<JDataSource>> {
    return new Promise<JDataGridPagedResponse<JDataSource>>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources`,
            params: {
              page: page + 1, // watch out API's page is 1-indexed
              size,
              q: getFormattedGridQueryForJMC(filterModel, sortModel)
            }
          })
          .then((response: any) => {
            const apiResponse = {
              page: response.page,
              result: response.result.map((sds: any) => getDataSourceFromRaw(sds))
            }
            resolve(apiResponse)
          })
          .catch((error: any) => {
            console.error(`Server error while getting all spatial data sources of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting all spatial data sources of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  getCount(organizationId: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources?page=1&size=1`
          })
          .then((response: any) => resolve(response.page.totalElements))
          .catch((error: any) => {
            console.error(`Server error while getting count of spatial data sources of organization "${organizationId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting cout of spatial data sources of organization "${organizationId}"`, ex)
        reject(ex)
      }
    })
  },
  create(organizationId: string, sds: JDataSourceSubmitValues): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources`,
            params: sds
          })
          .then((response: any) => resolve(response.result.id))
          .catch((error: any) => {
            console.error("Server error while creating spatial data source", error)
            reject(error)
          })
      } catch (ex) {
        console.error("Unexpected error while creating spatial data source", ex)
        reject(ex)
      }
    })
  },
  async update(organizationId: string, sds: JDataSourceSubmitValues): Promise<void> {
    const resp = await ajaxSVC.patch({
      url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sds.id}`,
      params: sds
    })
    return
  },
  delete(organizationId: string, sourceId: string): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while deleting spatial data source "${sourceId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while deleting spatial data source "${sourceId}"`, ex)
        reject(ex)
      }
    })
  },
  addTag(organizationId: string, sourceId: string, tagName: string): Promise<JTag> {
    return new Promise<JTag>((resolve, reject) => {
      try {
        ajaxSVC
          .post({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}/tags`,
            params: { tag: tagName }
          })
          .then(resp => resolve({ id: resp.result, name: tagName, organizationId }))
          .catch((error: any) => {
            console.error(`Server error while adding tag to data source "${sourceId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while adding tag to data source "${sourceId}"`, ex)
        reject(ex)
      }
    })
  },
  deleteTag(organizationId: string, sourceId: string, tagId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .del({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}/tags/${tagId}`
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while deleting tag from data source "${sourceId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while adding tag to data source "${sourceId}"`, ex)
        reject(ex)
      }
    })
  },
  getFeatures(organizationId: string, sourceId: string, offset: number, limit: number): Promise<GeoJSON.FeatureCollection> {
    return new Promise<GeoJSON.FeatureCollection>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.DAS)}/rest/v1/organizations/${organizationId}/collections/${sourceId}/items?startindex=${offset}&limit=${limit}`
          })
          .then(features => resolve(features))
          .catch((error: any) => {
            console.error(`Server error while getting features from data source "${sourceId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting features from data source "${sourceId}"`, ex)
        reject(ex)
      }
    })
  },
  getFeatureCount(organizationId: string, sourceId: string): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.DAS)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}/feature-count`
          })
          .then(featureCountResponse => resolve(featureCountResponse.result))
          .catch((error: any) => {
            console.error(`Server error while getting feature count from data source "${sourceId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting feature count from data source "${sourceId}"`, ex)
        reject(ex)
      }
    })
  },

  getDataSourceExtent(organizationId: string, dataSource: JDataSource): Promise<JMapBoxBounds> {
    const getExtentPromise = (dataSource1: JDataSource): Promise<any> => {
      // TODO: validate that the crs query param is the good one (backend hasn't been modified at the time of this writing)
      switch (dataSource1.type) {
        case DATA_SOURCE_TYPES.FILE:
        // case DATA_SOURCE_TYPES.VECTOR:
        // case DATA_SOURCE_TYPES.OGC_API_FEATURES:
        case DATA_SOURCE_TYPES.WMS_WMTS:
          return ajaxSVC.get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.DAS)}/rest/v1/organizations/${organizationId}/spatialdatasources/${dataSource1.id}/extent?crs=EPSG:4326`
          })

        case DATA_SOURCE_TYPES.RASTER:
          return ajaxSVC.get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.MAP_IMAGE)}/rest/v1/organizations/${organizationId}/spatialdatasources/${dataSource1.id}/extent?crs=EPSG:4326`
          })
        // default:
        //   throw Error(`Unsupported data source type "${dataSource1.type}"`)
      }
    }
    return new Promise<JMapBoxBounds>((resolve, reject) => {
      try {
        getExtentPromise(dataSource)
          .then(response => {
            const extent = response.result
            resolve({ sw: { lat: extent.y1, lng: extent.x1 }, ne: { lat: extent.y2, lng: extent.x2 } })
          })
          .catch((error: any) => {
            console.error(`Server error while getting data source extent from data source "${dataSource.id}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while getting data source extent from data source "${dataSource.id}"`, ex)
        reject(ex)
      }
    })
  },
  getUsersPermissions(organizationId: string, sourceId: string): Promise<JDataSourceAcl[]> {
    return new Promise<JDataSourceAcl[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}/permissions`
          })
          .then((response: any) => resolve(response.result))
          .catch((error: any) => {
            console.error("Error while getting my project ACLs from server", error)
            reject("organization.datasource.get.user.permissions.error.server")
          })
      } catch (ex) {
        console.error("Error while getting user project permissions", ex)
        reject("organization.datasource.get.user.permissions.error.unexpected")
      }
    })
  },
  getUserPermissions(organizationId: string, sourceId: string): Promise<DATA_SOURCE_PERMISSIONS[]> {
    return new Promise<DATA_SOURCE_PERMISSIONS[]>((resolve, reject) => {
      try {
        ajaxSVC
          .get({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}/permissions/self`
          })
          .then((response: any) => resolve(response.result[0].permissions))
          .catch((error: any) => {
            console.error("Error while getting my project ACLs from server", error)
            reject("organization.datasource.get.user.permissions.error.server")
          })
      } catch (ex) {
        console.error("Error while getting user project permissions", ex)
        reject("organization.datasource.get.user.permissions.error.unexpected")
      }
    })
  },
  updateUsersPermission(organizationId: string, sourceId: string, acls: JDataSourceAcl[]): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      try {
        ajaxSVC
          .put({
            url: `${getMicroServiceBaseUrlById(JSERVER_MICRO_SERVICE_IDS.CONFIGURATION)}/rest/v1/organizations/${organizationId}/spatialdatasources/${sourceId}/permissions`,
            params: acls
          })
          .then(() => resolve())
          .catch((error: any) => {
            console.error(`Server error while updating ACLs for project "${sourceId}"`, error)
            reject(error)
          })
      } catch (ex) {
        console.error(`Unexpected error while updating ACLs for project "${sourceId}"`, ex)
        reject(ex)
      }
    })
  }
}

function getDataSourceFromRaw(rawSDS: any): JDataSource {
  return {
    ...rawSDS,
    creationDate: getDateFromISOString(rawSDS.creationDate),
    lastModificationDate: getDateFromISOString(rawSDS.lastModificationDate),
    tags: Array.isArray(rawSDS.tags) ? rawSDS.tags : []
  }
}
